import {Tab, Table, TableBody, TableContainer, TablePagination, Tabs, useTheme,alpha} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import MarketTableRow from './marketTableRow';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'fav', label: 'Fav', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'priceChange', label: '24h Change', align: 'left' },
  { id: 'volumeChange', label: '24h Volume', align: 'left' },
  { id: 'marketCap', label: 'Market Cap', align: 'left' },
  { id: 'chart', label: '', align: 'left' },
  { id: 'trade', label: '', align: 'left' },
  { id: 'buy', label: '', align: 'left' },
];

// ----------------------------------------------------------------------

export default function MarketTable({
  tableData,
  marketTab,
  setMarketTab,
  typeTab,
  setTypeTab,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  children,
}) {
  const { t } = useTranslation();

    const theme = useTheme();
  return (
    <>
      <Tabs
        value={marketTab}
        onChange={(e, v) => setMarketTab(v)}
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          '& button': {
            typography: 'body1',
            width: 140,
            color: 'grey.300',
            borderRadius: 1.5,
            minHeight: 40,
            '&.Mui-selected': { color: 'warningButton', bgcolor: 'rgba(242, 127, 22, 0.1)' },
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab
          label={t('Favorites')}
          value='favorites'
          icon={<Iconify icon='eva:star-outline' sx={{ width: 18, height: 18 }} />}
        />
        <Tab label={t('All Cryptos')} value='all_cryptos' sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
        <Tab label={t('Spot Markets')} value='spot_markets' sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}}/>
        <Tab label={t('Futures Markets')} value='futures_markets' sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}}/>
        <Tab label={t('New Markets')} value='new_markets'sx={{ borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
      </Tabs>

      <Tabs
        value={typeTab}
        onChange={(e, v) => setTypeTab(v)}
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          my: 2,
          '& button': {
            typography: 'body1',
            width: 100,
            color: 'grey.300',
            borderRadius: 1.5,
            minHeight: 40,
            '&.Mui-selected': { color: 'common.white', bgcolor: 'grey.600' },
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab label={t('All')} value='All' sx={{ minWidth: 60, width: '60px !important' }} />
        <Tab label={t('Metaverse')} value='Metaverse' />
        <Tab label={t('Gaming')} value='Gaming' />
        <Tab label={t('Defi')} value='DeFi' sx={{ minWidth: 60, width: '60px !important' }} />
        {/* <Tab label={t('Innovation')} value='Innovation' /> */}
        <Tab label={t('Layer1')} value='Layer1' />
        <Tab label={t('Layer2')} value='Layer2' />
        <Tab label={t('Fan Token')} value='Fan_Token' />
        <Tab label={t('NFT')} value='NFT' sx={{ minWidth: 60, width: '60px !important' }} />
        <Tab label={t('Storage')} value='Storage' />
        {/* <Tab label={t('Palkadot')} value='Palkadot' />
        <Tab label={t('POS')} value='POS' sx={{ minWidth: 60, width: '60px !important' }} />
        <Tab label={t('POW')} value='POW' sx={{ minWidth: 60, width: '60px !important' }} /> */}
      </Tabs>

      {children}

      <Scrollbar>
        <TableContainer sx={{ position: 'relative' }}>
          <Table size={'small'}>
            <TableHeadCustom headLabel={TABLE_HEAD} rowCount={tableData?.length || 0} />

            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <MarketTableRow key={index} row={row} />
                  ))}

                  <TableNoData isNotFound={!tableData.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {tableData?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component='div'
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      )}
    </>
  );
}
