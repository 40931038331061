import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';

import userLightEarth from '../../assets/stats-illustration.webp';
import userDarkEarth from '../../assets/stats-illustration.webp';
import adminLightLogin from '../../assets/admin/device-card.webp';
import adminDarkLogin from '../../assets/admin/device-card.webp';
import Image from '../Image';
import LanguagePopover from '../mainNavBar/languagePopover';
import ThemeSwitch from '../mainNavBar/themeSwitch';
import { useTranslation } from 'react-i18next';
import { navigationUrl } from '../../navigationUrl';
import logo from "../../assets/l_logo.png";
import logo_dark from "../../assets/l_logo_dark.png";

const AuthLayout = ({ children, isAdmin = false, subTitle, linkText = 'Create an Account', linkTo = '/register' }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'center', md: 'space-around' }}
      alignItems={{ xs: 'center', md: 'normal' }}
    >
      <Stack justifyContent={'space-between'}>
        <Box sx={{ mb: 3 }}>
          <Stack direction='row' alignItems={'center'} sx={{ mt: { xs: 3, md: 6.7 } }} justifyContent={'space-between'}>
            <Stack
              direction={theme.direction === 'rtl' ? 'row-reverse' : 'row'}
              alignItems={'center'}
              spacing={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate(navigationUrl.landing)}
            >
              <Image src={theme?.palette?.mode === 'dark' ? logo :logo_dark} sx={{ width: 'auto', height : 22  }} />
              {/*<Typography variant='subtitle1' fontFamily={'Graphik'}>*/}
              {/*  {t('Cryptizi')}*/}
              {/*</Typography>*/}
            </Stack>

            <Stack direction='row'>
              <ThemeSwitch />
              <LanguagePopover lightIcon={false} />
            </Stack>
          </Stack>

          {subTitle && (
            <Typography variant='body4' color='grey.100' sx={{ mt: 1, ml: 2 }}>
              {subTitle}
            </Typography>
          )}
        </Box>

        <Box>{children}</Box>

        <Box sx={{ mb: 7, mt: 3 }}>
          {linkTo && (
            <Link component={RouterLink} variant='body3' to={linkTo}>
              {t(linkText)}
            </Link>
          )}
        </Box>
      </Stack>

      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{ height: { xs: '100%', md: '100vh' }, position: 'relative', display: { xs: 'none', md: 'flex' } }}
      >
        {isAdmin ? (
          <>
            {theme.palette.mode === 'light' && (
              <Box
                sx={{
                  position: 'absolute',
                  width: { md: 240, lg: 300 },
                  height: { md: 240, lg: 300 },
                  top: { md: '55%', lg: '60%' },
                  left: '50%',
                  transform: 'translateX(-50%)',
                  background: 'rgba(17, 202, 190, 0.9)',
                  filter: 'blur(100.5px)',
                }}
              />
            )}
            <Image
              src={theme.palette.mode === 'dark' ? adminDarkLogin : adminLightLogin}
              sx={{ height: { xs: '90%', md: 250, lg: 450 } }}
            />
          </>
        ) : (
          <Image
            src={theme.palette.mode === 'dark' ? userDarkEarth : userLightEarth}
            sx={{ height: { xs: '90%', md: 400, lg: 510 } }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default AuthLayout;
