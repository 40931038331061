import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import Slick from 'react-slick';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import Api from '../../../http/adminHttp/serviceApiAdmin';
import SymbolIcon from '../../../components/SymbolIcon';
import Image from '../../../components/Image';

import PMIcon from '../../../assets/logo/PM.svg';
import PSIcon from '../../../assets/logo/PS.svg';
import AIOIcon from '../../../assets/logo.png';
import CryptiziGIcon from '../../../assets/logo/cryptizi-g-symbol.png';
import CarouselArrows from '../../../components/carousel/CarouselArrows';

const SYMBOLS = [
  { value: 'USDT', type: 'symbol' },
  { value: 'BTC', type: 'symbol' },
  { value: 'ETH', type: 'symbol' },
  { value: 'BNB', type: 'symbol' },
  { value: 'DOGE', type: 'symbol' },
  { value: 'SHIB', type: 'symbol' },
  { value: 'MATIC', type: 'symbol' },
  { value: 'TRX', type: 'symbol' },
  { value: 'DASH', type: 'symbol' },
  { value: 'XRP', type: 'symbol' },
  { value: 'PerfectMoney', type: PMIcon },
  { value: 'PaySafe', type: PSIcon },
  { value: 'AIO', type: AIOIcon },
  // { value: 'Cryptizii', type: CryptiziGIcon },
];

export default function StatisticSymbolTable() {
  const { t } = useTranslation();
  const theme = useTheme();

  // --------------------
  const [tableData, setTableData] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('USDT');

  // --------------------
  const TABLE_HEAD = [
    { id: 'title', label: `${t('Title')}: ${selectedSymbol}`, align: 'left' },
    { id: 'today', label: 'Today', align: 'center' },
    { id: 'yesterday', label: 'Yesterday', align: 'center' },
    { id: 'week', label: 'This week', align: 'center' },
    { id: 'month', label: 'This month', align: 'center' },
    { id: 'year', label: 'This Year', align: 'center' },
  ];

  // --------------------
  const getData = async () => {
    try {
      const response = await Api.getSymbolStatisticsByDate(selectedSymbol);
      setTableData([
        {
          title: 'Orders',
          today: response?.orders?.today || 0,
          yesterday: response?.orders?.yesterday || 0,
          week: response?.orders?.week || 0,
          month: response?.orders?.month || 0,
          year: response?.orders?.year || 0,
        },
        {
          title: 'Amount of orders',
          today: response?.ordersAmount?.today || 0,
          yesterday: response?.ordersAmount?.yesterday || 0,
          week: response?.ordersAmount?.week || 0,
          month: response?.ordersAmount?.month || 0,
          year: response?.ordersAmount?.year || 0,
        },
        {
          title: 'Purchases',
          today: response?.purchases?.today || 0,
          yesterday: response?.purchases?.yesterday || 0,
          week: response?.purchases?.week || 0,
          month: response?.purchases?.month || 0,
          year: response?.purchases?.year || 0,
        },
        {
          title: 'Sales number',
          today: response?.sales?.today || 0,
          yesterday: response?.sales?.yesterday || 0,
          week: response?.sales?.week || 0,
          month: response?.sales?.month || 0,
          year: response?.sales?.year || 0,
        },
        {
          title: 'Purchases amount',
          today: response?.purchasesAmount?.today || 0,
          yesterday: response?.purchasesAmount?.yesterday || 0,
          week: response?.purchasesAmount?.week || 0,
          month: response?.purchasesAmount?.month || 0,
          year: response?.purchasesAmount?.year || 0,
        },
        {
          title: 'Sales amount',
          today: response?.salesAmount?.today || 0,
          yesterday: response?.salesAmount?.yesterday || 0,
          week: response?.salesAmount?.week || 0,
          month: response?.salesAmount?.month || 0,
          year: response?.salesAmount?.year || 0,
        },
      ]);
    } catch (e) {}
  };

  // ---------------------
  useEffect(() => {
    getData();
  }, [selectedSymbol]);

  // ---------------------
  const carouselRef = useRef(null);
  const [selectSlide, setSelectSlide] = useState(0);
  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };
  const handleNext = () => {
    carouselRef.current?.slickNext();
  };
  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 18,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: false,
    centerPadding: '0px',
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setSelectSlide(next),
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 14,
        },
      },
    ],
  };

  // ---------------------
  return (
    <>
      <Box sx={{ mb: 2, position: 'relative' }}>
        <CarouselArrows
          // filled
          onPrevious={handlePrevious}
          onNext={handleNext}
          customIcon={'eva:arrow-ios-forward-fill'}
          selectSlide={selectSlide}
          sx={{
            mx: 3,
            '& .arrow': {
              // mt: '-14px',
              '&.left': { left: -10 },
              '&.right': { right: -10 },
              // '& button': { width: 28, height: 28, borderRadius: '50%', p: 0.75 },
            },
          }}
        >
          <Slick ref={carouselRef} {...sliderSettings}>
            {SYMBOLS.map((symbol) => (
              <Box key={symbol.value}>
                <IconButton
                  onClick={() => setSelectedSymbol(symbol.value)}
                  sx={{
                    opacity: selectedSymbol === symbol.value ? 1 : 0.6,
                    transform: selectedSymbol === symbol.value ? 'scale(1)' : 'scale(0.8)',
                    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  }}
                >
                  {symbol.type === 'symbol' ? (
                    <SymbolIcon symbol={symbol.value} width={45} height={45} />
                  ) : (
                    <Image src={symbol.type} sx={{ width:symbol.value === 'AIO' ?'auto' : 45, height: 45 }} />
                  )}
                </IconButton>
              </Box>
            ))}
          </Slick>
        </CarouselArrows>
      </Box>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <StatisticSymbolTableRow key={index} row={row} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}

// ---------------------
const StatisticSymbolTableRow = ({ row }) => {
  const { t } = useTranslation();
  const { title, today, yesterday, week, month, year } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{t(title)}</TableCell>
      <TableCell align='center'>{today}</TableCell>
      <TableCell align='center'>{yesterday}</TableCell>
      <TableCell align='center'>{week}</TableCell>
      <TableCell align='center'>{month}</TableCell>
      <TableCell align='center'>{year}</TableCell>
    </TableRow>
  );
};
