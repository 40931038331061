import React from 'react';
import { Image, Row, Col } from 'antd';
import imgEarth from '../../../assets/earth.svg';
import classes from './verifySoftOtp.module.scss';
import logo from '../../../assets/l_logo.png';
import VerifySoftOtpForm from './verifySoftOtpForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import logo_dark from "../../../assets/l_logo_dark.png";
import {useTheme} from "@mui/material";

const VerifySoftOtp = () => {
  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div>
            <Image src={theme?.palette?.mode === 'dark' ? logo :logo_dark} sx={{ width: 'auto', height : 24  }} />

          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='space-around' align='middle'>
        <Col {...colPropsForm}>
          <VerifySoftOtpForm />
        </Col>
        <Col {...colPropsImage}>
          <Image height={450} src={imgEarth} preview={false} />
        </Col>
      </Row>

      <Row justify='space-around' style={{ marginBottom: '70px' }}></Row>
    </div>
  );
};

export default VerifySoftOtp;
