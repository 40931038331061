import React,{ useEffect, useRef, useState, memo } from 'react';
import { Box, Button, IconButton, InputAdornment, Slider, Stack, Tab, Tabs, Typography, useTheme,alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import { NumberField } from '../../../components/NumberField';
import Iconify from '../../../components/Iconify';
import Api from '../../../http/serviceApi';
import useWallet from '../../../hooks/useWallet';
import { fFloat } from '../../../utils/formatNumber';
import FuturesLeverageModal from './futuresModals/futuresLeverageModal';
import FuturesCalculatorModal from './futuresModals/futuresCalculatorModal';
import FutureMarginModeModal from './futuresModals/FutureMarginModeModal';
import FutureTransferModal from '../futuresWallet/FutureTransferModal';
import useFutureWallet from '../futuresWallet/useFutureWallet';


const FuturesForm = ({ selectedSymbol, selectPrice, onReload, onShowSymbolModal }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // ----------------
  const [type, setType] = useState('LIMIT');
  const [price, setPrice] = useState('');
  const [avbl, setAvbl] = useState(0);
  const [max, setMax] = useState(0);
  const [amount, setAmount] = useState('');
  const [cost, setCost] = useState(0);
  const [stopPrice, setStopPrice] = useState('');
  const [leverage, setLeverage] = useState(20);
  const [showLeverageModal, setShowLeverageModal] = useState(false);
  const [showCalculatorModal, setShowCalculatorModal] = useState(false);
  const [transferModal ,setTransferModal] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(null);
  const [quoteAssetWallet, setQuoteAssetWallet] = useState(null);
  const [marginModeModal , setMarginModeModal] = useState(false)
  const [marginMode , setMarginMode] = useState('CROSS') // CROSS , ISOLATED
  // ---------------
  const onSubmit = async (side = 'BUY') => {
    if (!amount) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }
    if (type === 'STOP_LIMIT' && (!price || !stopPrice)) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }
    if (type === 'LIMIT' && !price) {
      return enqueueSnackbar(t('Please fill in all fields'), { variant: 'warning' });
    }

    setIsSubmitting(side);

    try {
      await Api.addPositionFutures({
        amount: amount !== '' ? +amount : 0,
        leverage,
        price: price !== '' ? +price : 0,
        side,
        stopPrice: stopPrice !== '' ? +stopPrice : 0,
        symbol: selectedSymbol.baseAsset,
        type,
        futuresType:marginMode
      });

      onReload();
      onReloadWallet();

      setPrice('');
      setAmount('');
      setStopPrice('');
    } catch (e) {}

    setIsSubmitting(null);
  };

  const refreshWallet = () =>{
    onReloadWallet();

  }

  const futuresChangeLeverage = async (e) =>{
    try {
       const data = await Api.futuresChangeLeverage(e , `${selectedSymbol?.baseAsset}${selectedSymbol.quoteAsset}`)
    }catch (e) {

    }
  }
  // ------ WALLET ----------
  // const { userWallet, onReloadWallet } = useWallet(true);
  //
  // useEffect(() => {
  //   const found = userWallet.walletInfos.find((w) => w.symbol === selectedSymbol.quoteAsset);
  //   found && setQuoteAssetWallet(found);
  // }, [userWallet, selectedSymbol]);

  const {userWallet : userWalletFuture , onReloadWallet} =useFutureWallet()
  // useEffect(()=>{
  //
  // })
  const reloadRef = useRef();
  const onReloadForWallet = () => {
    clearTimeout(reloadRef.current);

    reloadRef.current = setTimeout(() => {
      onReloadWallet();
      onReloadForWallet();
    }, 30000);
  };

  useEffect(() => {
    onReloadForWallet();
    return () => {
      clearTimeout(reloadRef.current);
    };
  }, []);

  // ---------------
  useEffect(() => {
    calculate();
  }, [avbl, leverage, price, selectedSymbol, type, userWalletFuture?.available]);

  // ---------------
  const calculate = (newAmount = null) => {
    if ((type === 'LIMIT' || type === 'STOP_LIMIT') && price) {
      let calAmount = ((avbl || 0) * (leverage || 0)) / price;
      if (newAmount !== null) calAmount = +newAmount;

      if (newAmount === null) setAmount(calAmount);
      setCost(((calAmount || 0) * price) / (leverage || 0));
      setMax(((userWalletFuture?.available || 0) * (leverage || 0)) / price);
    }

    if (type === 'MARKET' && selectedSymbol?.lastPrice) {
      let calAmount = ((avbl || 0) * (leverage || 0)) / selectedSymbol?.lastPrice;
      if (newAmount !== null) calAmount = +newAmount;

      if (newAmount === null) setAmount(calAmount);
      setCost(((calAmount || 0) * selectedSymbol?.lastPrice) / (leverage || 0));
      setMax(((userWalletFuture?.available || 0) * (leverage || 0)) / selectedSymbol?.lastPrice);
    }
  };

  // ----------------
  useEffect(() => {
    if (selectPrice) {
      setPrice(selectPrice);
    }
  }, [selectPrice]);

    const theme = useTheme();

  // ----------------
  return (
    <>
      <Stack alignItems='center' direction={'row'} justifyContent={'space-between'} sx={{ mt: 1, mb: 3 ,px:3}}>
        <Button
          variant='contained'
          size='small'
          color='inherit'
          sx={{
            borderRadius: 0.5,
            bgcolor: 'grey.400',
            typography: 'body3',
            width: '45%',
            '&:hover': { bgcolor: 'grey.600' },
          }}
          onClick={() => setMarginModeModal(true)}
        >
          {t(marginMode)}
        </Button>
        <Button
          variant='contained'
          size='small'
          color='inherit'
          sx={{
            borderRadius: 0.5,
            bgcolor: 'grey.400',
            typography: 'body3',
            width: '45%',
            '&:hover': { bgcolor: 'grey.600' },
          }}
          onClick={() => setShowLeverageModal(true)}
        >
          {leverage}x
        </Button>
      </Stack>

      <Tabs
        value={type}
        onChange={(e, newTab) => setType(newTab)}
        sx={{
          px: 1,
          '& button': {
            typography: 'body1',
            color: 'grey.300',
            borderRadius: 1.5,
            minHeight: 40,
            '&.Mui-selected': { color: 'warningButton', bgcolor: 'transparent' },
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab label={t('Limit')} value={'LIMIT'} sx={{ minWidth: 55 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
        <Tab label={t('Market')} value={'MARKET'} sx={{ minWidth: 70 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
        <Tab label={t('Stop Limit')} value={'STOP_LIMIT'} sx={{ minWidth: 80 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
      </Tabs>

      <Box sx={{ mt: 1, px: 2 }}>
        <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
          <Stack direction={'row'} alignItems='center' spacing={1}>
            <Typography color={'grey.300'} typography='body1'>
              {t('Avbl')}
            </Typography>
            <Typography color={'common.white'} typography='body1'>
              {userWalletFuture?.available ? `${userWalletFuture?.available} USDT` : '...'}
            </Typography>
            <IconButton onClick={() => setTransferModal(true)}>
              <Iconify icon='gg:arrows-exchange-alt-v' sx={{ width: 20, height: 20 , transform:"rotate(90deg)" }} />
            </IconButton>
          </Stack>

          <IconButton onClick={() => setShowCalculatorModal(true)}>
            <Iconify icon='solar:calculator-outline' sx={{ width: 20, height: 20 }} />
          </IconButton>
        </Stack>

        {type === 'STOP_LIMIT' && (
          <CustomNumberField
            label={t('Stop Price')}
            symbol={selectedSymbol.quoteAsset}
            value={stopPrice}
            onChange={setStopPrice}
            onShowSymbolModal={onShowSymbolModal}
          />
        )}

        {(type === 'LIMIT' || type === 'STOP_LIMIT') && (
          <CustomNumberField
            label={t('Price')}
            symbol={selectedSymbol.quoteAsset}
            value={price}
            onChange={setPrice}
            onShowSymbolModal={onShowSymbolModal}
          />
        )}

        <CustomNumberField
          label={t('Size')}
          symbol={selectedSymbol.baseAsset}
          value={amount}
          onChange={(v) => {
            setAmount(v);
            calculate(v);
          }}
          onShowSymbolModal={onShowSymbolModal}
        />

        <Box sx={{ mx: 1, mt: 2 }}>
          <Slider
            valueLabelDisplay='auto'
            size='small'
            step={5}
            // marks
            min={0}
            max={userWalletFuture?.available || 0}
            value={avbl}
            onChange={(e, newValue) => setAvbl(newValue)}
            sx={{ mt: 1.5 }}
          />
        </Box>

        <CalculateData
          firstLabel='Buy'
          firstValue={fFloat(amount || 0)}
          secondLabel='Sell'
          secondValue={fFloat(amount || 0)}
          symbol={selectedSymbol.baseAsset}
        />

        <Stack direction='row' alignItems='center' spacing={1} sx={{ mt: 8, mb: 1 }}>
          <CustomButton
            label={`${t('Buy')}/${t('Long')}`}
            onClick={() => onSubmit('BUY')}
            loading={isSubmitting === 'BUY'}
            disabled={isSubmitting !== null}
          />

          <CustomButton
            label={`${t('Sell')}/${t('Short')}`}
            color='error'
            onClick={() => onSubmit('SELL')}
            loading={isSubmitting === 'SELL'}
            disabled={isSubmitting !== null}
          />
        </Stack>

        <CalculateData
          firstLabel='Cost'
          firstValue={fFloat(cost)}
          secondLabel='Cost'
          secondValue={fFloat(cost)}
          symbol={selectedSymbol.quoteAsset}
        />

        <CalculateData
          firstLabel='Max'
          firstValue={fFloat(max)}
          secondLabel='Max'
          secondValue={fFloat(max)}
          symbol={selectedSymbol.baseAsset}
        />
      </Box>

      <FuturesLeverageModal
        open={showLeverageModal}
        onClose={() => setShowLeverageModal(false)}
        defaultValue={leverage}
        symbol={`${selectedSymbol.baseAsset}${selectedSymbol.quoteAsset}`}
        onConfirm={(e)=>{
          setLeverage(e)
          futuresChangeLeverage(e)
        }}
      />


      <FutureMarginModeModal
        open={marginModeModal}
        onClose={() => {
          setMarginModeModal(false)
        }}
        defaultValue={marginMode}
        symbol={`${selectedSymbol.baseAsset}${selectedSymbol.quoteAsset}`}
        onConfirm={setMarginMode}

      />


      <FuturesCalculatorModal
        open={showCalculatorModal}
        onClose={() => setShowCalculatorModal(false)}
        initLeverage={leverage}
        selectedSymbol={selectedSymbol}

        />
      <FutureTransferModal avbl={userWalletFuture?.available} open={transferModal} onClose={()=>setTransferModal(false)} refreshWallet={refreshWallet}/>

    </>
  );
};

export default memo(
  FuturesForm,
  (prev, next) => prev.selectedSymbol === next.selectedSymbol && prev.selectPrice === next.selectPrice
);

// --------------------------------
export const CustomNumberField = ({ value, onChange = () => {}, label, symbol, onShowSymbolModal = () => {} }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLight = theme.palette.mode === 'light';

  return (
    <NumberField
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label={t(label)}
      size='small'
      sx={{
        mt: 2.5,
        bgcolor: 'grey.600',
        borderRadius: 1,
        '& input': { typography: 'body1', pt: 1.4, color: 'common.white' },
        '& .MuiFormLabel-root': { color: 'grey.300' },
        ...(!isLight && { '& fieldset': { border: 'none' } }),
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position='end'
            sx={{ cursor: 'pointer', '&:hover': { color: 'grey.100' } }}
            onClick={onShowSymbolModal}
          >
            <Typography color='grey.300' variant='body3' sx={{ '&:hover': { color: 'grey.100' } }}>
              {symbol}
            </Typography>
            <Iconify icon='eva:arrow-ios-downward-outline' sx={{ width: 16, ml: 1 }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

// --------------------------------
const CustomButton = ({ label, color = 'primary', onClick, loading = false, disabled = false }) => {
  return (
    <LoadingButton
      variant='contained'
      size='large'
      fullWidth
      color={color}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      sx={{ height: 40, typography: 'subtitle1', pt: 1.2 }}
    >
      {label}
    </LoadingButton>
  );
};

// --------------------------------
const CalculateData = ({ firstLabel, firstValue, secondLabel, secondValue, symbol }) => {
  const { t } = useTranslation();

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography variant='body4' color='grey.300'>
          {t(firstLabel)}
        </Typography>
        <Typography variant='body4' color='common.white'>
          {firstValue} {symbol}
        </Typography>
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography variant='body4' color='grey.300'>
          {t(secondLabel)}
        </Typography>
        <Typography variant='body4' color='common.white'>
          {secondValue} {symbol}
        </Typography>
      </Stack>
    </Stack>
  );
};
