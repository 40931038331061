import React from 'react';
import { Image, Row, Col, Button } from 'antd';
import classes from './adminForgotPassOk.module.scss';
import logo from '../../../../assets/logo.png';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import { navigationUrl } from '../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const AdminForgotPassOk = () => {
  const { t } = useTranslation();

  let navigate = useNavigate();

  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };

  return (
    // <Row className={classes.container} justify="space-between" align="middle">
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div>
            <Image width={55} src={logo} preview={false} />
          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='center'>
        <Col {...colPropsForm}>
          <div className={classes.formTitleDiv}>
            <span className={classes.formTitleBig}>{t('Password reset')}</span>
            <span className={classes.formTitleSmall}>
              {t('your password has been successfully reset Click below to login magically')}
            </span>
            {/* <span className={classes.formTitleSmall}>Ali@gmail.com</span> */}
          </div>

          <Button
            type='primary'
            htmlType='submit'
            block
            shape='round'
            onClick={() => navigate(navigationUrl.admin_login)}
          >
            {t('Continue')}
          </Button>

          {/* <div className={classes.btnBackDiv}>
                        <HiOutlineArrowNarrowLeft className={classes.icon} />
                        <Button type="link" shape="round" className={classes.btnBack}>
                            Back to login
                        </Button>
                    </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default AdminForgotPassOk;
