import {alpha, Box, Card, Tab, Table, TableBody, TableContainer, TablePagination, Tabs, useTheme} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '../../../http/adminHttp/serviceApiAdmin';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../components/table';
import Scrollbar from '../../../components/Scrollbar';
import useTable from '../../../hooks/useTable';
import AdminNotificationTableRow from './adminNotificationTableRow';

export default function AdminNotificationTable({ reloadTime }) {
  const { t } = useTranslation();

  // ----------------
  const [tableData, setTableData] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [tab, setTab] = useState('headers');

  const TABLE_HEAD = [
    { id: 'index', label: 'Num', align: 'left' },
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'message', label: 'Message', align: 'left' },
    { id: 'types', label: 'Types', align: 'left' },
    { id: 'recipient', label: 'Recipient', align: 'center' },
    { id: 'color', label: 'Color', align: 'center' },
    ...(tab === 'headers'
      ? [
          { id: 'activeDays', label: 'Active days', align: 'center' },
          { id: 'delete', label: 'Delete', align: 'center' },
        ]
      : []),
  ];

  // ----------------
  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  // ----------------
  const getList = async () => {
    try {
      const response = await Api.getNotifications(0, 100000000);
      if (response) {
        setTableData(response.notificationInfos);
        setFiltered(response.notificationInfos);
      }
    } catch (e) {
      setTableData([]);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    getList();
  }, [reloadTime]);

  // ----------------------------------------------
  useEffect(() => {
    if (tableData?.length > 0) {
      if (tab === 'headers') {
        setFiltered(
          tableData.filter((f) => f.types.indexOf('FIXED_ALERT') !== -1 || f.types.indexOf('ONE_TIME_ALERT') !== -1)
        );
      } else {
        setFiltered(
          tableData.filter(
            (f) =>
              f.types.indexOf('NOTIFICATION') !== -1 || f.types.indexOf('EMAIL') !== -1 || f.types.indexOf('SMS') !== -1
          )
        );
      }
    }
  }, [tableData, tab]);
  const theme = useTheme();

  // --------------
  return (
    <Card sx={{ py: 3, mt: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)} variant='scrollable' scrollButtons='auto'  sx={{
          '& .Mui-selected': {
            color: 'primary.main', // Set the selected tab color to primary
            backgroundColor: alpha(theme.palette.primary.main, 0.2)

          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'primary.main', // Set the indicator color to primary
          },
        }}>
          <Tab label={t('Headers')} value='headers' sx={{ width: 140 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
          <Tab label={t('Notifications/ Email/ SMS')} value='others' sx={{ width: 220,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            }, }} />
        </Tabs>
      </Box>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filtered?.length || 0}
              onSort={onSort}
            />
            <TableBody>
              {filtered === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {filtered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <AdminNotificationTableRow key={index} row={row} index={index} onReload={getList} tab={tab} />
                  ))}

                  <TableNoData isNotFound={!filtered?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {filtered?.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={filtered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      )}
    </Card>
  );
}
