import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {alpha, useTheme} from '@mui/material';
import classesDark from './accountSetting.module.scss';
import classesLight from './accountSettingLight.module.scss';
import { BsPersonCheck } from 'react-icons/bs';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlinePhone } from 'react-icons/ai';
import AccountSettingBasicInfo from './accountSettingBasicInfo/accountSettingBasicInfo';
import AccountSettingChangePass from './accountSettingChangePass/accountSettingChangePass';
import AccountSettingTwoStep from './accountSettingTwoStep/accountSettingTwoStep';
import AccountSettingChangeEmail from './accountSettingChangeEmail/accountSettingChangeEmail';
import { SET_PAGE_STATE } from '../../../store/actionTypes';
import AccountSettingChangeMobile from './accountSettingChangeMobile/accountSettingChangeMobile';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import './accountSettingAntdStyles.scss';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const AccountSetting = ({ isAdmin = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store.userInfo);

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Account settings' },
    });
  }, []);

  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  const tabKey = {
    BasicInfo: 'basicInfo',
    ChangePass: 'changePass',
    TwoStepVerification: 'twoStepVerification',
    ChangeEmail: 'changeEmail',
    ChangeMobile: 'changeMobile',
  };

  const [activeKey, setActiveKey] = React.useState(tabKey.BasicInfo);

  const onChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className='accountSetting'>
      <Tabs defaultActiveKey={tabKey.BasicInfo} onChange={onChange} activeKey={activeKey}   sx={{
        '& .Mui-selected': {
          color: 'primary.main', // Set the selected tab color to primary
          backgroundColor: alpha(theme.palette.primary.main, 0.2)

        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'primary.main', // Set the indicator color to primary
        },
      }}>
        <TabPane
          key={tabKey.BasicInfo}
          tab={
            <div className={activeKey === tabKey.BasicInfo ? classes.tabTitleDivActive : classes.tabTitleDiv} style={{marginLeft:"1rem" , marginRight:"1rem"}}>
              <span>
                <BsPersonCheck className={classes.tabTitleIcon} />
              </span>
              <span className={classes.tabTitleText}>{t('Basic information')}</span>
            </div>
          }
        >
          <AccountSettingBasicInfo isAdmin={isAdmin} />
        </TabPane>
        <TabPane
          key={tabKey.ChangePass}
          tab={
            <div className={activeKey === tabKey.ChangePass ? classes.tabTitleDivActive : classes.tabTitleDiv}>
              <HiOutlineLockClosed className={classes.tabTitleIcon} />
              {t('Change Password')}
            </div>
          }
        >
          <AccountSettingChangePass isAdmin={isAdmin} />
        </TabPane>
        <TabPane
          key={tabKey.TwoStepVerification}
          tab={
            <div className={activeKey === tabKey.TwoStepVerification ? classes.tabTitleDivActive : classes.tabTitleDiv}>
              <CgArrowsExchangeAlt className={classes.tabTitleIcon} />
              {t('Two-step verification')}
            </div>
          }
        >
          <AccountSettingTwoStep isAdmin={isAdmin} />
        </TabPane>
        <TabPane
          key={tabKey.ChangeEmail}
          tab={
            <div className={activeKey === tabKey.ChangeEmail ? classes.tabTitleDivActive : classes.tabTitleDiv}>
              <AiOutlineMail className={classes.tabTitleIcon} />
              {t('Change email')}
            </div>
          }
        >
          <AccountSettingChangeEmail isAdmin={isAdmin} />
        </TabPane>

        <TabPane
          key={tabKey.ChangeMobile}
          tab={
            <div className={activeKey === tabKey.ChangeMobile ? classes.tabTitleDivActive : classes.tabTitleDiv}>
              <AiOutlinePhone className={classes.tabTitleIcon} />
              {userInfo?.mobileNumber ? t('Change Mobile') : t('Add Mobile')}
            </div>
          }
        >
          <AccountSettingChangeMobile isAdmin={isAdmin} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AccountSetting;
