import React,{ useEffect, useState } from 'react';
import {
    Avatar,
    Box, MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../../components/table';
import useTable from '../../../../../../hooks/useTable';
import AdminApi from "../../../../../../http/adminHttp/serviceApiAdmin";
import AdminTomanTransactionReceiptTableRow from "./AdminTomanTransactionReceiptTableRow";
import EmptyContent from "../../../../../../components/EmptyContent";
import FillSelect from "../../../../../../components/FillSelect";
import DatePicker from "../../../../../../components/DatePicker";
import SymbolIcon from "../../../../../../components/SymbolIcon";
import ModalContainer from "../../../../../../components/ModalContainer";
import moment from "jalali-moment";
import {useSelector} from "react-redux";

export const ACTION_FIAT_BY_R = [
    { value: 'ALL', label: 'All' },
    { value: 'DEPOSIT', label: 'Deposit' },
    { value: 'WITHDRAW', label: 'Withdraw' }
];

const TABLE_HEAD = [
    { id: 'num', label: 'Row', align: 'center' },
    { id: 'accountNumber', label: 'Account Number', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'center' },
    { id: 'depositType', label: 'Deposit Type', align: 'left' },
    { id: 'firstName', label: 'First Name', align: 'left' },
    { id: 'lastName', label: 'Last Name', align: 'left' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: 'trackingCode', label: 'Tracking Code', align: 'center' },
    { id: 'userId', label: 'User ID', align: 'center' },
    // { id: 'stripeTxId', label: 'Stripe Tx ID', align: 'center' },
    // { id: 'fiatType', label: 'Fiat Type', align: 'left' },
    // { id: 'createDate', label: 'Create Date', align: 'left' },
    // { id: 'cardInfo', label: 'Card Type / Gateway', align: 'center' },
    // { id: 'emailAddress', label: 'Email Address', align: 'center' },
    // { id: 'mobile', label: 'Mobile', align: 'center' },
    // { id: 'receiptImage', label: 'Receipt Image', align: 'center' },
    { id: 'detail', label: 'Detail', align: 'center' },
];


const AdminTomanTransactionByReceipt = ({userId}) => {

    const [action, setAction] = useState('ALL'); //ALL, DEPOSIT, WITHDRAW

    const { t } = useTranslation();
    const language = useSelector((store) => store.language);

    const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
        defaultOrderBy: '',
    });

    const [tableData, setTableData] = useState(null);
    const [dateFromFilter, setDateFromFilter] = useState(new Date(new Date().setMonth(new Date().getMonth() - 12)));
    const [dateToFilter, setDateToFilter] = useState(new Date());
    const [modalData , setModalData] = useState(undefined)
    const fetchData = async () => {
        setTableData(null);

        const filters = {
            userId,
            action,
            endTime: dateToFilter?.valueOf?.(),
            startTime: dateFromFilter?.valueOf?.(),
        };

        try {
            const response = await AdminApi.adminConfirmFiatTransaction(page, rowsPerPage, filters);
            if (response?.status === 'SUCCESS') {
                setTableData(response?.transactionInfos);
            }
        } catch (e) {}
    };

    useEffect(() => {
        fetchData();
    }, [
        page,
        rowsPerPage,
        action,
        dateFromFilter ,
        dateToFilter
    ]);


    return (
        <>
            <Stack spacing={1}>
                <Typography variant='h6' fontWeight={500} sx={{ mt: 5 }}>
                    {t('Wallet transactions')}
                </Typography>

                <Typography variant='body3' color={'grey.100'}>
                    {t('User wallet transactions that have been changed by this admin')}
                </Typography>
            </Stack>


            <Stack spacing={3} sx={{mt:4 , mb:4}}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
                    <FillSelect
                        variant='standard'
                        value={action}
                        onChange={setAction}
                        label={t('Action')}
                        sx={{ width: { xs: '100%', md: 220 } }}
                    >
                        {ACTION_FIAT_BY_R.map((item) => (
                            <MenuItem key={item.value} value={item.value} sx={{ typography: 'body2' }}>
                                {t(item.label)}
                            </MenuItem>
                        ))}
                    </FillSelect>

                    <Stack direction={'row'}>
                        <DatePicker
                            label={t('From')}
                            value={dateFromFilter}
                            setValue={setDateFromFilter}
                            variant='standard'
                            maxDate={dateToFilter}
                            fullWidth
                            sx={{ maxWidth: 150 }}
                            InputProps={null}
                        />
                        <DatePicker
                            label={t('To')}
                            value={dateToFilter}
                            setValue={setDateToFilter}
                            variant='standard'
                            minDate={dateFromFilter}
                            fullWidth
                            sx={{ maxWidth: 150 }}
                        />
                    </Stack>
                </Stack>

            </Stack>

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                    <Table size='small'>
                        <TableHeadCustom
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData?.length || 0}
                            onSort={onSort}
                        />
                        <TableBody>
                            {tableData === null ? (
                                <TableLoading isLoading />
                            ) : (
                                <>
                                    {
                                        tableData?.length > 0 ?
                                        tableData?.map((row, index) => (
                                        <AdminTomanTransactionReceiptTableRow setModalData={setModalData} key={row.id} row={row} index={index} />

                                        )) :
                                            <TableRow>
                                                <TableCell colSpan={17}>
                                                    <EmptyContent
                                                        title=''
                                                        description={t('There is no record for this table')}
                                                        sx={{
                                                            '& span.MuiBox-root': { height: 160 },
                                                        }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                    }

                                    {/*<TableNoData isNotFound={!tableData?.length} />*/}

                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>

            <Box sx={{ position: 'relative' }}>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component='div'
                    count={tableData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    labelRowsPerPage={t('Rows per page')}
                    labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
                />
            </Box>

            <ModalContainer open={modalData !== undefined} onClose={()=>setModalData(undefined)} title={'Details'} width={600}>
                <Stack sx={{ mt: 4.5, alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 130,
                            borderRadius: '22px',
                            textAlign: 'center',
                            m: 'auto',
                            p: 0.8,
                            bgcolor: modalData?.status === 'SUCCESS' ? 'rgba(48, 224, 161, 0.2)' : 'rgba(255, 69, 58, 0.2)',
                        }}
                    >
                        <Typography variant='body2' color={modalData?.status === 'SUCCESS' ? '#30E0A1' : '#FF453A'}>
                            {modalData?.status}
                        </Typography>
                    </Box>

                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('User ID')}
                        </Typography>
                        <Typography variant='body3' color={'grey.100'}>
                            {modalData?.userId || '-'}
                        </Typography>
                    </Stack>


                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('Stripe Tx ID')}
                        </Typography>
                        <Typography variant='body3' color={'grey.100'}>
                            {modalData?.stripeTxId || '-'}
                        </Typography>
                    </Stack>

                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('Fiat Type') }
                        </Typography>
                        <Typography variant='body3' color={'grey.100'}>
                            {modalData?.fiatType || '-'}
                        </Typography>
                    </Stack>

                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('Create Date')}
                        </Typography>
                        <Typography variant='body4' color={'grey.100'}>
                            {moment(modalData?.createDate).locale(language).format('D MMM YYYY - HH:mm') || '-'}
                        </Typography>
                    </Stack>


                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('Card Type / Gateway')}
                        </Typography>
                        <span>

                            <Typography variant='body3'>{modalData?.cardType}</Typography>
                            <Typography variant='body3'>/</Typography>
                            <Typography variant='body3'>{modalData?.shetabGateway}</Typography>
                        </span>
                    </Stack>

                    {/*<Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>*/}
                    {/*    <Typography variant='body3' color={'grey.400'}  >*/}
                    {/*        {t('Decimal')}*/}
                    {/*    </Typography>*/}
                    {/*    <Typography variant='body3' color={'grey.100'}>*/}
                    {/*        {modalData?.shetabGateway || '-'}*/}
                    {/*    </Typography>*/}
                    {/*</Stack>*/}

                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('Email Address')}
                        </Typography>
                        <Typography variant='body3' color={'grey.100'}>
                            {modalData?.emailAddress || '-'}
                        </Typography>
                    </Stack>


                    <Stack width={'80%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} spacing={4}>
                        <Typography variant='body3' color={'grey.400'}  >
                            {t('Mobile')}
                        </Typography>
                        <Typography variant='body3' color={'grey.100'}>
                            {modalData?.mobile || '-'}
                        </Typography>
                    </Stack>


                    {modalData?.receiptImageUrl && (
                        <Avatar
                            alt="Receipt Image"
                            src={modalData?.receiptImageUrl}
                            sx={{ width: 300, height: 300, m: 'auto' }}
                        />
                    )}
                </Stack>
            </ModalContainer>
        </>
    );
};

export default AdminTomanTransactionByReceipt;