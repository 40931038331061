import { useEffect, useState } from 'react';
import { Box, Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';

import useTable, { getComparator } from '../../../../hooks/useTable';
import { PageTitle } from '../../../../components/PageTitle';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import { applySortFilter, capitalizeFirstLetter } from '../../../../utils';
import CryptoTransactionTableRow from './cryptoTransactionTableRow';
import Api from '../../../../http/serviceApi';
import AdminApi from '../../../../http/adminHttp/serviceApiAdmin';
import CryptoTransactionTableToolbar from './cryptoTransactionTableToolbar';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import useQuery from '../../../../hooks/useQuery';
import { exportExcel } from '../../../../utils/excel';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import { exportPDF } from '../../../../utils/pdf';

// ----------------------------------------------------------------------

export default function CryptoTransactionTable({ onlyInternalType = false, isAdmin = false }) {
  const language = useSelector((store) => store.language);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: onlyInternalType ? 'Internal Transactions' : 'Crypto Transactions' },
    });

    setTableData(null);
    setTablePagination({ totalItems: 0 });
  }, [onlyInternalType]);

  const TABLE_HEAD = [
    { id: 'createDate', label: 'Time', align: 'left' },
    ...(isAdmin ? [{ id: 'user', label: 'User', align: 'left' }] : []),
    { id: 'transactionType', label: 'Type', align: 'left' },
    { id: 'symbol', label: 'Asset', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'left' },
    { id: 'address', label: 'Origin(User ID)', align: 'left' },
    // ...(!isAdmin ? [{ id: 'address2', label: 'Address', align: 'left' }] : []),
    { id: 'transactionId', label: 'TxID', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
  ];

  const [typeFilter, setTypeFilter] = useState(
      onlyInternalType ? 'Withdraw' : capitalizeFirstLetter(query.get('type')) || 'Deposit'
  ); // Deposit, Withdraw
  const [depositStatusFilter, setDepositStatusFilter] = useState('ALL'); // ALL, CREDITED, PENDING, SUCCESS
  const [withdrawStatusFilter, setWithdrawStatusFilter] = useState('All'); // All, Awaiting_Approval, Cancelled, Completed, Email_Sent, Failure, Processing, Rejected
  const [fiatTypeFilter, setFiatTypeFilter] = useState('ALL'); // ALL, AUD, BRL, EUR, GBP, IRR, NGN, PLN, RON, RUB, TRY, UAH, USD, ZAR
  const [timeFilter, setTimeFilter] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
  });
  const [transactionIdFilter, setTransactionIdFilter] = useState('');
  const [symbolFilter, setSymbolFilter] = useState('');

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState(null);
  const [tablePagination, setTablePagination] = useState({ totalItems: 0 });

  const getData = async () => {
    try {
      const filters = {
        type: onlyInternalType ? 'Internal_Withdraw' : typeFilter,
        fiatType: fiatTypeFilter,
        allTransaction: 'false',
        endTime: timeFilter.endTime?.valueOf?.(),
        startTime: timeFilter.startTime?.valueOf?.(),
        depositStatus: depositStatusFilter,
        withdrawStatus: withdrawStatusFilter,
        fiatStatus: 'SUCCESS',
        ...(transactionIdFilter && { transactionId: transactionIdFilter }),
        ...(symbolFilter && { symbol: symbolFilter }),
      };

      if (isAdmin) {
        const response = await AdminApi.getReportTransaction(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          if (!onlyInternalType && typeFilter === 'Deposit') {
            setTableData(response.cryptoDepositInfos);
            setTablePagination({ totalItems: response.cryptoDepositInfosTotalPages * rowsPerPage });
          } else {
            setTableData(response.cryptoWithdrawsInfos);
            setTablePagination({ totalItems: response.cryptoWithdrawsInfosTotalPages * rowsPerPage });
          }
        } else {
          setTableData([]);
        }
      } else {
        const response = await Api.getUserTransaction(page, rowsPerPage, filters);
        if (response.status === 'SUCCESS') {
          if (!onlyInternalType && typeFilter === 'Deposit') {
            setTableData(response.cryptoDepositInfos);
            setTablePagination({ totalItems: response.cryptoDepositInfosTotalPages * rowsPerPage });
          } else {
            setTableData(response.cryptoWithdrawsInfos);
            setTablePagination({ totalItems: response.cryptoWithdrawsInfosTotalPages * rowsPerPage });
          }
        } else {
          setTableData([]);
        }
      }
    } catch (e) {
      setTableData([]);
    }
  };

  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    typeFilter,
    depositStatusFilter,
    withdrawStatusFilter,
    fiatTypeFilter,
    timeFilter,
    transactionIdFilter,
    symbolFilter,
    onlyInternalType,
  ]);

  // --------------------
  const [isExportLoading, setIsExportLoading] = useState(false);
  // --------------------
  const onExport = async (type = 'Excel') => {
    setIsExportLoading(true);

    const filters = {
      type: onlyInternalType ? 'Internal_Withdraw' : typeFilter,
      fiatType: fiatTypeFilter,
      allTransaction: 'false',
      endTime: timeFilter.endTime?.valueOf?.(),
      startTime: timeFilter.startTime?.valueOf?.(),
      depositStatus: depositStatusFilter,
      withdrawStatus: withdrawStatusFilter,
      fiatStatus: 'SUCCESS',
      ...(transactionIdFilter && { transactionId: transactionIdFilter }),
      ...(symbolFilter && { symbol: symbolFilter }),
    };

    try {
      let response;
      if (isAdmin) {
        response = await AdminApi.getReportTransaction(0, 10000000, filters);
      } else {
        response = await Api.getUserTransaction(0, 10000000, filters);
      }

      let result;
      if (!onlyInternalType && typeFilter === 'Deposit') {
        result = response.cryptoDepositInfos;
      } else {
        result = response.cryptoWithdrawsInfos;
      }

      const heads = [
        '#',
        t('Time'),
        ...(isAdmin ? [t('Name'), t('Email'), t('Mobile')] : []),
        t('Type'),
        t('Asset'),
        t('Amount'),
        t('Origin'),
        t('TxID'),
      ];

      const columnsWidth = [5, 15, ...(isAdmin ? [18, 30, 13] : []), 11, 12, 12, 50, 98];

      // generate excel and download
      const config = {
        language,
        fileName: [onlyInternalType ? t('Internal Transaction') : t('Crypto Transaction')],
        heads: heads,
        columnsWidth,
        list: result.map((item) => [
          moment(item.createdDate).locale(language).format('YYYY-MM-DD HH:mm'),
          ...(isAdmin ? [`${item.firstName} ${item.lastName}`, item.emailAddress || '-', item.mobile || '-'] : []),
          item.transactionType,
          item.symbol,
          item.amount,
          item.address,
          item.transactionId,
        ]),
      };
      type === 'PDF' ? exportPDF(config) : exportExcel(config);
    } catch (e) {
      console.log(e);
    }

    setIsExportLoading(false);
  };

  // ----------------------------------------------
  return (
      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mx: { xs: 2, lg: 0 } }}>
        <PageTitle
            title={'History of your transactions'}
            subTitle={'You can enter the name and symbol of Coin, transaction ID, etc. in the search field'}
        />

        <Box sx={{ mt: 5 }}>
          <CryptoTransactionTableToolbar
              isAdmin={isAdmin}
              onlyInternalType={onlyInternalType}
              typeFilter={typeFilter}
              fiatTypeFilter={fiatTypeFilter}
              setFiatTypeFilter={setFiatTypeFilter}
              depositStatusFilter={depositStatusFilter}
              withdrawStatusFilter={withdrawStatusFilter}
              timeFilter={timeFilter}
              transactionIdFilter={transactionIdFilter}
              symbolFilter={symbolFilter}
              setTypeFilter={setTypeFilter}
              setDepositStatusFilter={setDepositStatusFilter}
              setWithdrawStatusFilter={setWithdrawStatusFilter}
              setTimeFilter={setTimeFilter}
              setTransactionIdFilter={setTransactionIdFilter}
              setSymbolFilter={setSymbolFilter}
              isExportLoading={isExportLoading}
              onExport={onExport}
          />

          <Scrollbar>
            <TableContainer sx={{ position: 'relative' }}>
              <Table size={'small'}>
                <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length || 0}
                    onSort={onSort}
                />

                <TableBody>
                  {tableData === null ? (
                      <TableLoading isLoading />
                  ) : (
                      <>
                        {applySortFilter({
                          tableData,
                          comparator: getComparator(order, orderBy),
                        }).map((row, index) => (
                            <CryptoTransactionTableRow key={index} row={row} isAdmin={isAdmin} />
                        ))}

                        <TableNoData isNotFound={!tableData?.length} />
                      </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {tableData?.length > 0 && (
              <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 200]}
                  component='div'
                  count={tablePagination.totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  labelRowsPerPage={t('Rows per page')}
                  labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
              />
          )}
        </Box>
      </Card>
  );
}
