import { Button, Card, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Image from '../../../../components/Image';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';

import USDT from '../../../../assets/logo/USDT.svg';
import Iconify from '../../../../components/Iconify';

export default function WithdrawCryptoList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Withdraw Crypto' },
    });
  }, []);

  return (
    <Card>
      <Typography variant='h6' sx={{ mb: 5 }}>
        {t('Recent Withdrawals')}
      </Typography>

      <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ mb: 5 }}>
        <CustomButton title={t('AIO Address')} />
        <CustomButton title={t('AIO user')} />
      </Stack>

      <Stack spacing={2}>
        {new Array(3).fill(1).map((n, index) => (
          <Fragment key={index}>
            {/* ***************** One record ************************ */}
            <Stack spacing={1.5}>
              {/* *****************row 1************************ */}
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Image src={USDT} sx={{ width: 24, height: 24 }} />
                <Typography variant='body2' color={'grey.100'}>
                  559.965 USDT
                </Typography>
                <Typography
                  variant='body4'
                  color={'#30E0A1'}
                  sx={{ backgroundColor: 'rgba(48, 224, 161, 0.1)', borderRadius: 0.5, px: 2.5, py: 0.75 }}
                >
                  {t('Completed')}
                </Typography>
              </Stack>
              {/* ****************row 2************************* */}
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant='body3' color={'grey.300'}>
                  2022-07-05 22:10
                </Typography>

                <Stack direction={'row'} spacing={1}>
                  <Typography variant='body3' color={'grey.300'}>
                    {t('Network')}
                  </Typography>
                  <Typography variant='body2' color={'grey.100'}>
                    {t('TRX')}
                  </Typography>
                </Stack>

                <Stack direction={'row'} spacing={1}>
                  <Typography variant='body3' color={'grey.300'}>
                    {t('Address')}
                  </Typography>
                  <Typography variant='body2' color={'grey.100'}>
                    yZcLjsPKjN1CCLvKTrM0NvKtI/edit#gid=147
                  </Typography>
                </Stack>

                <Stack direction={'row'} spacing={1}>
                  <Typography variant='body3' color={'grey.300'}>
                    {t('Withdraw wallet')}
                  </Typography>
                  <Typography variant='body2' color={'grey.100'}>
                    {t('Spot Wallet')}
                  </Typography>
                </Stack>

                <IconButton onClick={() => {}}>
                  <Iconify icon={'eva:arrow-ios-forward-outline'} />
                </IconButton>
              </Stack>

              {/* ********************row 3********************* */}

              <Stack direction={'row'} spacing={1}>
                <Typography variant='body3' color={'grey.300'}>
                  {t('TxID')}
                </Typography>
                <Typography variant='body2' color={'grey.100'}>
                  09374893....skfjhiqwr
                </Typography>
              </Stack>
            </Stack>
            {/* ***************** end one record ************************ */}

            {index < 2 && <Divider />}
          </Fragment>
        ))}
      </Stack>

      <Button color='inherit' size='large' sx={{ mt: 5, borderRadius: 1, typography: 'body3', width: 100 }}>
        {t('View All')}
      </Button>
    </Card>
  );
}

const CustomButton = ({ title }) => (
  <Button
    sx={{ typography: 'body2', borderRadius: 0.5, color: 'grey.100', backgroundColor: 'grey.600', p: '9px 20px' }}
  >
    {title}
  </Button>
);
