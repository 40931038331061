import React from 'react';
import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../components/table';
import TokenRow from './tokenRow';

// ===================================================================

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'symbol', label: 'Symbol', align: 'left' },
  { id: 'isActive', label: 'Status', align: 'left' },
  { id: 'view', label: 'View', align: 'center' },
  { id: 'edit', label: 'Edit', align: 'center' },
  { id: 'delete', label: 'Delete', align: 'center' },

];

// ===================================================================

const TokenTable = ({ tableData, page, rowsPerPage, onChangePage, onChangeRowsPerPage, handleEdit, handleView ,handleDelete}) => {
  const { t } = useTranslation();

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom headLabel={TABLE_HEAD} rowCount={tableData?.length} />

            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData.map((row, index) => (
                    <TokenRow
                      key={row.symbol}
                      row={row}
                      index={index}
                      handleEdit={handleEdit}
                      handleView={handleView}
                      handleDelete={handleDelete}
                    />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {tableData?.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component='div'
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
          />
        </Box>
      )}
    </>
  );
};

export default TokenTable;
