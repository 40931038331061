import React from 'react';
import { Image, Row, Col } from 'antd';
import adminLightLogin from '../../../assets/admin/device-card.webp';
import adminDarkLogin from '../../../assets/admin/device-card.webp';
import classes from './adminVerifyEmail.module.scss';
import logo from '../../../assets/logo.png';
import AdminVerifyEmailForm from './adminVerifyEmailForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AdminVerifyEmail = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };

  return (
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div>
            <Image width={55} src={logo} preview={false} />
          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='space-around' align='middle'>
        <Col {...colPropsForm}>
          <AdminVerifyEmailForm />
        </Col>
        <Col {...colPropsImage}>
          <Image height={450} src={theme.palette.mode === 'dark' ? adminDarkLogin : adminLightLogin} preview={false} />
        </Col>
      </Row>

      <Row justify='space-around' style={{ marginBottom: '70px' }}>
        <Col {...colPropsForm}>
          <a href='' className={classes.loginCreateAccount}>
            {t('Login')}
          </a>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>
    </div>
  );
};

export default AdminVerifyEmail;
