import React from 'react';
import { TableRow, TableCell, IconButton, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Iconify from '../../../../components/Iconify';

const TokenRow = ({ row, handleEdit, handleView , index , handleDelete}) => {
  const { t } = useTranslation();
  const { isActive, name, symbol, depositActive, withdrawActive } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1.5, typography: 'body2' } }}>
      <TableCell>{index+1}</TableCell>

      <TableCell>{name}</TableCell>

      <TableCell>{symbol}</TableCell>

      <TableCell align='center'>
        <Box
          sx={{
            maxWidth: 130,
            borderRadius: '22px',
            textAlign: 'center',
            py: 0.8,
            px: 2,
            ...(isActive ? { bgcolor: 'rgba(48, 224, 161, 0.2)' } : { bgcolor: 'rgba(250, 34, 86, 0.2)' }),
          }}
        >
          <Typography
            variant='body4'
            sx={{
              lineHeight: 1,
              py: 0.4,
              ...(isActive ? { color: '#30E0A1' } : { color: 'error.darker' }),
            }}
          >
            {t(isActive ? 'Active' : 'Disabled')}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align='center' >
        <IconButton onClick={() => handleView(row)} sx={{ mr: { xs: 0, md: 1 } }}>
          <Iconify icon='eva:eye-outline' />
        </IconButton>
      </TableCell>


      <TableCell align='center' >
        <IconButton>
          <Iconify icon='eva:edit-2-outline' onClick={() => handleEdit(row)} />
        </IconButton>
      </TableCell>
        <TableCell align='center' >
            <IconButton>
                <Iconify icon='eva:trash-2-outline' onClick={() => handleDelete(row)} />
            </IconButton>
        </TableCell>
    </TableRow>
  );
};

export default TokenRow;
