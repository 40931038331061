import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import MainNavBar from '../../components/mainNavBar/mainNavBar';
import SideMenu from '../../components/sideMenu/sideMenu';
import useResponsive from '../../hooks/useResponsive';
import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import { useRef } from 'react';
import Api from '../../http/serviceApi';
import NotificationFixed from '../../components/mainNavBar/NotificationFixed';
import AfterGatewayModal from "../../components/AfterGatewayModal";

// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 250,
  DASHBOARD_WIDTH: 250,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 30,
    paddingBottom: 20,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function MainLayout() {
  const [open, setOpen] = useState(false);

  // ------------------
  const { loading } = useUserAuth(true);

  // ------------------
  const isDesktop = useResponsive('up', 'lg');
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });
  const isCollapse = collapse.click && !collapse.hover;

  // ------------------
  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  // ------------------
  const onToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  // ------------------
  const onHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  // ------------------
  const onHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  // ------------------
  const [notifications, setNotifications] = useState();
  const notifReloadRef = useRef();

  // ------------------
  const getNotification = async () => {
    clearTimeout(notifReloadRef.current);

    try {
      const response = await Api.getNotifications();
      setNotifications(response);

      notifReloadRef.current = setTimeout(() => {
        getNotification();
      }, 60000);
    } catch (e) {}
  };

  // ------------------
  useEffect(() => {
    if (!loading) {
      getNotification();
    }
  }, [loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AfterGatewayModal justModal={true}/>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <MainNavBar isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} notifications={notifications} />

        <SideMenu
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          onHoverLeave={onHoverLeave}
          onToggleCollapse={onToggleCollapse}
          onHoverEnter={onHoverEnter}
          isCollapse={isCollapse}
          collapse={collapse}
        />

        <MainStyle collapseClick={collapse.click}>
          <NotificationFixed list={notifications?.fixedAlertInfos || []} />
          <NotificationFixed list={notifications?.oneTimeAlertInfos || []} onReload={getNotification} allowSeen />

          <Outlet />
        </MainStyle>
      </Box>
    </>
  );
}
