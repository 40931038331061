import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Box, Stack, Card, Tab, Tabs, Grid, useTheme, alpha} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import BriefInfoCard from '../../../../components/BriefInfoCard';
import useUserInit from './hooks/useUserInit';
import UserHeader from './userHeader';
import { FormProvider, RHFRadioGroup } from '../../../../components/hookForm';
import useUserForm from './hooks/useUserForm';
import useUserSubmit from './hooks/useUserSubmit';
import BasicTab from './userLevels/basicTab';
import IntermediateTab from './userLevels/intermediateTab';
import AdvancedTab from './userLevels/advancedTab';
import OtherTab from './userLevels/otherTab';
import ButtonsComponent from './buttonsComponent';
import CryptoWallet from './userTabs/cryptoWallet';
import FiatTransaction from './userTabs/fiatTransaction';
import CryptoTransaction from './userTabs/cryptoTransaction';
import Orders from './userTabs/orders';
import FiatWallet from './userTabs/fiatWallet';
import Cards from './userTabs/cards';
import Earn from './userTabs/earn';
import BlockModal from './userLevels/blockModal';
import NotificationModal from './userLevels/notificationModal/notificationModal';
import TradeHistory from './userTabs/tradeHistory';
import LoadingScreen from '../../../../components/LoadingScreen';

const EditUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: userId } = useParams();

  const [tab, setTab] = useState('BASIC');
  const [walletTab, setWalletTab] = useState(0);
  const [blockModal, setBlockModal] = useState(false);
  const [notifModal, setNotifModal] = useState(false);

  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  const handleWalletTabChange = (e, newTab) => {
    setWalletTab(newTab);
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Edit User' },
    });
  }, []);

  let updateProfile = false;
  if (tab === 'BASIC') {
    updateProfile = true;
  }

  let updatePolicy = false;
  if (tab === 'OTHER') {
    updatePolicy = true;
  }

  const { userStatistic, userInfo, onEarnFilter, earnsFiltered, getUserInfo, countries, loading } = useUserInit();

  const { handleSubmit, isSubmitting, methods, handleDropAvatar, handleDropBill, handleDropPassport } = useUserForm(
    userInfo,
    updateProfile,
    updatePolicy
  );

  const { onSubmit } = useUserSubmit(userInfo, getUserInfo, updateProfile, updatePolicy);

  let requested = false;
  if (userInfo?.authenticationLevel === 'INTERMEDIATE_REQUESTED' && tab === 'INTERMEDIATE') {
    requested = true;
  } else if (userInfo?.authenticationLevel === 'ADVANCED_REQUESTED' && tab === 'ADVANCED') {
    requested = true;
  } else if (userInfo?.authenticationLevel === 'BASIC_REQUESTED' && tab === 'BASIC') {
    requested = true;
  }
  const theme = useTheme();

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 2.5 }}>
        <Grid container spacing={3}>
          {userStatistic.map((data, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <BriefInfoCard data={data} />
            </Grid>
          ))}
        </Grid>

        <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 }, mt: 3 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <UserHeader data={userInfo} handleDrop={handleDropAvatar} />

            <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: 584, mt: 7 }}>
              <Tabs value={tab} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'   sx={{
                '& .Mui-selected': {
                  color: 'primary.main', // Set the selected tab color to primary
                  backgroundColor: alpha(theme.palette.primary.main, 0.2)

                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main', // Set the indicator color to primary
                }
              ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}}>
                <Tab label={t('Basic')} value='BASIC' sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
                <Tab label={t('Intermediate')} value='INTERMEDIATE' sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
                <Tab label={t('Advanced')} value='ADVANCED' sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
                <Tab label={t('Other')} value='OTHER' sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              </Tabs>
            </Box>

            <TabPanel value={tab} index={'BASIC'}>
              <BasicTab countries={countries} />
            </TabPanel>
            <TabPanel value={tab} index={'INTERMEDIATE'}>
              <IntermediateTab data={userInfo} handleDrop={handleDropPassport} />
            </TabPanel>
            <TabPanel value={tab} index={'ADVANCED'}>
              <AdvancedTab data={userInfo} handleDrop={handleDropBill} />
            </TabPanel>
            <TabPanel value={tab} index={'OTHER'}>
              <OtherTab />
            </TabPanel>

            {requested && (
              <Stack sx={{ mt: 5 }}>
                <RHFRadioGroup
                  name='status'
                  options={[
                    { label: t('Approve'), value: 'approve', disabled: userInfo?.authenticationLevel === tab },
                    { label: t('Reject'), value: 'reject', disabled: userInfo?.authenticationLevel === tab },
                  ]}
                  sx={{ flexDirection: 'column' }}
                />
              </Stack>
            )}

            <ButtonsComponent
              userInfo={userInfo}
              isSubmitting={isSubmitting}
              setBlockModal={setBlockModal}
              setNotifModal={setNotifModal}
              showSaveButton={requested || updateProfile || updatePolicy}
            />
          </FormProvider>
        </Card>

        <Card sx={{ py: 5, px: 4, mt: 5 }}>
          <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: '100%', mt: 7 }}>
            <Tabs value={walletTab} onChange={handleWalletTabChange} variant='scrollable' scrollButtons='auto'>
              <Tab label={t('Crypto wallet')} sx={{ width: 140 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Fiat wallet')} sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Fiat Transactions')} sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Crypto Transaction')} sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Cards')} sx={{ width: 140 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Orders')} sx={{ width: 140 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Trade history')} sx={{ width: 146 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Earn')} sx={{ width: 140 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
            </Tabs>
          </Box>

          <TabPanel value={walletTab} index={0}>
            <CryptoWallet userId={userId} />
          </TabPanel>
          <TabPanel value={walletTab} index={1}>
            <FiatWallet userId={userId} />
          </TabPanel>
          <TabPanel value={walletTab} index={2}>
            <FiatTransaction userId={userId} />
          </TabPanel>
          <TabPanel value={walletTab} index={3}>
            <CryptoTransaction userId={userId} />
          </TabPanel>
          <TabPanel value={walletTab} index={4}>
            <Cards userId={userId} userInfo={userInfo} />
          </TabPanel>
          <TabPanel value={walletTab} index={5}>
            <Orders userId={userId} />
          </TabPanel>
          <TabPanel value={walletTab} index={6}>
            <TradeHistory userId={userId} />
          </TabPanel>
          <TabPanel value={walletTab} index={7}>
            <Earn userId={userId} />
          </TabPanel>
        </Card>
      </Stack>

      <BlockModal open={blockModal} onClose={() => setBlockModal(false)} onReload={getUserInfo} userInfo={userInfo} />

      <NotificationModal open={notifModal} onClose={() => setNotifModal(false)} userId={userId} />
    </>
  );
};

export default EditUser;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 2.5 }}>{children}</Box>}
    </div>
  );
}
