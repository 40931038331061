import { Avatar, Button, Col, Image, Row, Select, Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import classes from './userManagement.module.scss';
import iconUser from '../../../../assets/user.svg';
import BasicForm from './basicForm/basicForm';
import IntermediateForm from './intermediateForm/intermediateForm';
import AdvancedForm from './advancedForm/advancedForm';
import OtherForm from './otherForm/otherForm';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { convertTimeStampToDate } from '../../../../utils/dateConverter';
import { useSnackbar } from 'notistack';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';
import {alpha, useTheme} from "@mui/material";

const { TabPane } = Tabs;

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};
const icons = importAll(require.context('../../../../assets/admin/user', false, /\.(png|jpe?g|svg)$/));

const tabKey = {
  Basic: 'basic',
  Intermediate: 'intermediate',
  Advanced: 'advanced',
  Other: 'other',
};

const UserManagement = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(tabKey.Orders);
  const [adminUserInfo, setAdminUserInfo] = useState({
    amount: 0,
    condition: '',
    buyAmount: 0,
    buyCount: 0,
    documents: [],
    fiatAmount: 0,
    message: '',
    sellAmount: 0,
    sellCount: 0,
    userInfo: {
      active: true,
      address: '',
      authenticationLevel: '',
      birthday: 0,
      changePassword: true,
      cityName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      phoneNumber: '',
      postalCode: '',
      registeredBy: '',
      registeredDate: 0,
      twoStepLogin: true,
      userId: 0,
    },
  });
  const location = useLocation();

  const colPropsTopItems = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 6,
  };
  const colPropsInfoDiv = {
    xs: 24,
    sm: 13,
    md: 10,
    lg: 8,
    xl: 7,
  };

  useEffect(() => {
    getUser(location?.state?.adminUser?.userId || 35);
  }, []);

  const onTabChange = (key) => {
    setActiveKey(key);
  };
  const theme = useTheme();

  const getUser = async (userId) => {
    setLoading(true);
    const token = window.localStorage.getItem('adminToken');
    const response = await Api.getUser(userId, token);
    if (response?.status === 'SUCCESS') {
      setAdminUserInfo({
        ...response,
        amount: location?.state?.adminUser?.amount,
        // authenticationLevel: location?.state?.adminUser?.authenticationLevel,
        condition: location?.state?.adminUser?.condition,
      });
    }
    setLoading(false);
    console.log(response);
  };

  const onBasicSaveClick = (data) => {};

  const onAdvanceSaveClick = (approvement) => {
    if (approvement.target.value === 'Approve') {
      confirmAuthenticationLevel();
    } else if (approvement.target.value === 'Reject') {
      rejectAuthenticationLevel();
    }
  };

  const onIntermediateSaveClick = (approvement) => {
    debugger;
    if (approvement.target.value === 'Approve') {
      confirmAuthenticationLevel();
    } else if (approvement.target.value === 'Reject') {
      rejectAuthenticationLevel();
    }
  };

  const onOtherSaveClick = (data) => {
    debugger;
    adminOtherPolicy(data);
  };

  const onBlockClick = () => {
    blockUser();
  };

  const confirmAuthenticationLevel = async () => {
    const token = window.localStorage.getItem('adminToken');
    const response = await Api.confirmAuthenticationLevel(adminUserInfo?.userInfo?.userId, token);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('seved successfully.'), { variant: 'success' });
      getUser(location?.state?.adminUser?.userId);
    }
  };

  const rejectAuthenticationLevel = async () => {
    const token = window.localStorage.getItem('adminToken');
    const data = {
      userId: adminUserInfo?.userInfo?.userId,
      message: 'test',
    };
    const response = await Api.rejectAuthenticationLevel(data, token);
    if (response?.status === 'SUCCESS') {
      getUser(location?.state?.adminUser?.userId);
    }
  };

  const blockUser = async () => {
    debugger;
    const token = window.localStorage.getItem('adminToken');
    const response = await Api.blockUser(adminUserInfo?.userInfo?.userId, true, token);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t(`user '${adminUserInfo?.userInfo?.userId}' blocked successfully.`), { variant: 'success' });
      getUser(location?.state?.adminUser?.userId);
    }
  };

  const adminOtherPolicy = async (data) => {
    const token = window.localStorage.getItem('adminToken');
    const response = await Api.adminOtherPolicy(adminUserInfo?.userInfo?.userId, data, token);
    if (response?.status === 'SUCCESS') {
      enqueueSnackbar(t('seved successfully.'), { variant: 'success' });
      getUser(location?.state?.adminUser?.userId);
    }
  };

  return (
    <>
      <div className='userManagementContainer'>
        <Row gutter={[10, 10]}>
          <Col {...colPropsTopItems}>
            <div className={classes.topItem}>
              <div style={{ backgroundColor: '#11cabe2a' }}>
                <img src={icons['people.svg']} alt='' />
              </div>
              <div>
                <span>{adminUserInfo?.buyAmount}</span>
                <span>{t('Buy')}</span>
              </div>
            </div>
          </Col>
          <Col {...colPropsTopItems}>
            <div className={classes.topItem}>
              <div style={{ backgroundColor: '#bc47fb26' }}>
                <img src={icons['profile-tick.svg']} alt='' />
              </div>
              <div>
                <span>{adminUserInfo?.sellAmount}</span>
                <span>{t('Sales')}</span>
              </div>
            </div>
          </Col>
          <Col {...colPropsTopItems}>
            <div className={classes.topItem}>
              <div style={{ backgroundColor: '#f2801625' }}>
                <img src={icons['user-octagon.svg']} alt='' />
              </div>
              <div>
                <span>{adminUserInfo?.buyCount}</span>
                <span>{t('Total Buy')}</span>
              </div>
            </div>
          </Col>
          <Col {...colPropsTopItems}>
            <div className={classes.topItem}>
              <div style={{ backgroundColor: '#fa225426' }}>
                <img src={icons['profile-delete.svg']} alt='' />
              </div>
              <div>
                <span>{adminUserInfo?.sellCount}</span>
                <span>{t('Total sales')}</span>
              </div>
            </div>
          </Col>
        </Row>
        <div className={classes.bottom}>
          <Row justify='space-between' gutter={[10, 10]}>
            <Col>
              <div className={classes.profile}>
                <Avatar
                  style={{ width: '100px', height: '100px' }}
                  src={
                    <img
                      src={
                        adminUserInfo?.documents?.find((item) => item.type === 'USER_IMAGE')?.data
                          ? `data:image/jpeg;base64,${
                              adminUserInfo?.documents?.find((item) => item.type === 'USER_IMAGE')?.data
                            }`
                          : iconUser
                      }
                      alt=''
                    />
                  }
                />
                <div>
                  <span>{adminUserInfo?.userInfo?.firstName + ' ' + adminUserInfo?.userInfo?.lastName}</span>
                  <span>{adminUserInfo?.userInfo?.authenticationLevel}</span>
                  <span style={{ color: '#FA2256' }}>{adminUserInfo?.userInfo?.active ? '' : 'Blocked'}</span>
                </div>
              </div>
            </Col>
            <Col {...colPropsInfoDiv}>
              <div className={classes.infoDiv}>
                <span>{t('Registration via')}: {adminUserInfo?.userInfo?.registeredBy}</span>
                <span>{t('User')}: -</span>
                <span>{t('User ID')}: {adminUserInfo?.userInfo?.userId}</span>
                <span>{t('Registration Date')}: {convertTimeStampToDate(adminUserInfo?.userInfo?.registeredDate)}</span>
              </div>
            </Col>
          </Row>
          {loading && (
            <div className={classes.loading}>
              <Spin />
            </div>
          )}

          <Tabs defaultActiveKey={tabKey.Basic} onChange={onTabChange} activeKey={activeKey}   sx={{
            '& .Mui-selected': {
              color: 'primary.main', // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2)

            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'primary.main', // Set the indicator color to primary
            },
          }}>
            <TabPane key={tabKey.Basic} tab='Basic'>
              <BasicForm
                formData={adminUserInfo?.userInfo}
                onSaveClick={onBasicSaveClick}
                onBlockClick={onBlockClick}
                saveLoading={saveLoading}
                blockLoading={blockLoading}
              />
            </TabPane>
            <TabPane key={tabKey.Intermediate} tab='Intermediate'>
              <IntermediateForm
                formData={adminUserInfo}
                onSaveClick={onIntermediateSaveClick}
                onBlockClick={onBlockClick}
                saveLoading={saveLoading}
                blockLoading={blockLoading}
              />
            </TabPane>
            <TabPane key={tabKey.Advanced} tab='Advanced'>
              <AdvancedForm
                formData={adminUserInfo}
                onSaveClick={onAdvanceSaveClick}
                onBlockClick={onBlockClick}
                saveLoading={saveLoading}
                blockLoading={blockLoading}
              />
            </TabPane>
            <TabPane key={tabKey.Other} tab='Other'>
              <OtherForm formData={adminUserInfo} onSaveClick={onOtherSaveClick} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
