import { Container, Divider, Link, Stack, styled, Typography } from '@mui/material';

import useUserAuth from '../../hooks/useUserAuth';
import LoadingScreen from '../../components/LoadingScreen';
import TopMenu from '../landing/sections/topMenu';
import Footer from '../landing/sections/footer';

export default function TermsOfUse() {
  const { loading } = useUserAuth(false);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Stack sx={{ minHeight: 1 }}>
      <TopMenu />

      <RootStyle>
        <Container>
          <Typography variant='h5'>Cryptizi Terms of Use</Typography>

          <Typography variant='body2' color='grey.300' sx={{ mt: 4 }}>
            Last revised: 17 February 2023
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            These Cryptizi Terms of Use is entered into between you (hereinafter referred to as “you” or “your”) and
            Cryptizi operators (as defined below). By accessing, downloading, using or clicking on “I agree” to accept
            any Cryptizi Services (as defined below) provided by Cryptizi (as defined below), you agree that you have
            read, understood and accepted all of the terms and conditions stipulated in these Terms of Use (hereinafter
            referred to as “these Terms”) as well as our Privacy Policy at{' '}
            <Link href='www.Cryptizi.com/privacy-policy' color='primary.main'>
              www.Cryptizi.com/privacy-policy
            </Link>
            . In addition, when using some features of the Services, you may be subject to specific additional terms and
            conditions applicable to those features.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 5, textAlign: 'justify' }}>
            Please read the terms carefully as they govern your use of Cryptizi Services.{' '}
            <strong>
              THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE
              RESOLVED BY WAY OF LEGALLY BINDING ARBITRATION
            </strong>
            .The terms of the arbitration provision are set forth in Article 10, “Resolving Disputes: Forum,
            Arbitration, Class Action Waiver”, hereunder. As with any asset, the values of Digital Currencies (as
            defined below) may fluctuate significantly and there is a substantial risk of economic losses when
            purchasing, selling, holding or investing in Digital Currencies and their derivatives.{' '}
            <strong>
              BY MAKING USE OF CRYPTIZI SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (1) YOU ARE AWARE OF THE RISKS
              ASSOCIATED WITH TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; (2) YOU SHALL ASSUME ALL RISKS
              RELATED TO THE USE OF CRYPTIZI SERVICES AND TRANSACTIONS OF DIGITAL CURRENCIES AND THEIR DERIVATIVES; AND
              (3) CRYPTIZI SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.
            </strong>
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            By accessing, using or attempting to use Cryptizi Services in any capacity, you acknowledge that you accept
            and agree to be bound by these Terms. If you do not agree, do not access Cryptizi or utilize Cryptizi
            services.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            I. Definitions
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>1. Cryptizi</strong> refers to an ecosystem comprising Cryptizi websites (whose domain names include
            but are not limited to ( https://www.cryptizi.com/ ), mobile applications, clients, applets and other
            applications that are developed to offer Cryptizi Services, and includes independently-operated platforms,
            websites and clients within the ecosystem (e.g. Cryptizi’s Open Platform, Cryptizi Launchpad, Cryptizi Labs,
            Cryptizi Charity, Cryptizi DEX, Cryptizi X, JEX, Trust Wallet, and fiat gateways). In case of any
            inconsistency between relevant terms of use of the above platforms and the contents of these Terms, the
            respective applicable terms of such platforms shall prevail.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>2. Cryptizi Accounts</strong> refer to the foundational virtual accounts, including main accounts
            and subaccounts, which are opened by Cryptizi for Users to record on Cryptizi their usage of Cryptizi
            Services, transactions, asset changes and basic information. Cryptizi Accounts serve as the basis for Users
            to enjoy and exercise their rights on Cryptizi.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>3. Cryptizi Fiat Account</strong> means the record of your fiat currencies’ balance (if any), which
            is enabled through an electronic money wallet (or similar account) service that is provided by a Fiat
            Partner.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>4. Cryptizi Operators</strong> refer to all parties that run Cryptizi, including but not limited to
            legal persons (including Cryptizi UAB), unincorporated organizations and teams that provide Cryptizi
            Services and are responsible for such services. For convenience, unless otherwise stated, references to
            “Cryptizi” and “we” in these Terms specifically mean Cryptizi Operators. UNDER THESE TERMS, CRYPTIZI
            OPERATORS MAY CHANGE AS CRYPTIZI’S BUSINESS ADJUSTS, IN WHICH CASE, THE CHANGED OPERATORS SHALL PERFORM
            THEIR OBLIGATIONS UNDER THESE TERMS WITH YOU AND PROVIDE SERVICES TO YOU, AND SUCH CHANGE DOES NOT AFFECT
            YOUR RIGHTS AND INTERESTS UNDER THESE TERMS. ADDITIONALLY, THE SCOPE OF CRYPTIZI OPERATORS MAY BE EXPANDED
            DUE TO THE PROVISION OF NEW CRYPTIZI SERVICES, IN WHICH CASE, IF YOU CONTINUE TO USE CRYPTIZI SERVICES, IT
            IS DEEMED THAT YOU HAVE AGREED TO JOINTLY EXECUTE THESE TERMS WITH THE NEWLY ADDED CRYPTIZI OPERATORS. IN
            CASE OF A DISPUTE, YOU SHALL DETERMINE THE ENTITIES BY WHICH THESE TERMS ARE PERFORMED WITH YOU AND THE
            COUNTERPARTIES OF THE DISPUTE, DEPENDING ON THE SPECIFIC SERVICES YOU USE AND THE PARTICULAR ACTIONS THAT
            AFFECT YOUR RIGHTS OR INTERESTS.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>5. Cryptizi Services</strong> refer to various services provided to you by Cryptizi that are based
            on Internet and/or blockchain technologies and offered via Cryptizi websites, mobile applications, clients
            and other forms (including new ones enabled by future technological development). Cryptizi Services include
            but are not limited to such Cryptizi ecosystem components as Digital Asset Trading Platforms, the financing
            sector, Cryptizi Labs, Cryptizi Academy, Cryptizi Charity, Cryptizi Info, Cryptizi Launchpad, Cryptizi
            Research, Cryptizi Chain, Cryptizi X, Cryptizi Fiat Gateway, existing services offered by Trust Wallet and
            novel services to be provided by Cryptizi.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>6. Cryptizi Platform Rules</strong> refer to all rules, interpretations, announcements, statements,
            letters of consent and other contents that have been and will be subsequently released by Cryptizi, as well
            as all regulations, implementation rules, product process descriptions, and announcements published in the
            Help Center or within products or service processes.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>7. Digital Currencies</strong> refer to encrypted or digital tokens or cryptocurrencies with a
            certain value that are based on blockchain and cryptography technologies and are issued and managed in a
            decentralized form.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>8. Digital Assets</strong> refer to Digital Currencies, their derivatives or other types of
            digitalized assets with a certain value.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>9. Fiat Services</strong> means: (a) crediting your Cryptizi Fiat Account with one or more fiat
            currencies either via a bank transfer, or with your debit card or credit card; (b) purchasing Digital Assets
            at spot prices with one or more fiat currencies by using either your Cryptizi Fiat Account balance, or your
            debit card or credit card; (c) selling Digital Assets at spot prices for fiat currencies and crediting the
            corresponding proceeds of sale either to your Cryptizi Fiat Account, or to your debit card or credit card;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (d) withdrawing one or more fiat currencies from your Cryptizi Fiat Account either into your bank account,
            or to your debit card or credit card.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>9. KYC</strong> refers to the “know-your-customer” process that Cryptizi has put in place before
            entering into a business relationship or conducting transactions with its Users. As part of this process,
            Cryptizi may do anything that it deems necessary in order to identify Users, verify their identity,
            scrutinize and investigate User transactions, or comply with any applicable law or regulation.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>10. Loan/Lending</strong> refers to Cryptizi’s lending of Digital Currencies to Users at an interest
            collected in certain ways (in the form of Digital Currencies), including but not limited to the leveraged
            trading and currency lending services currently offered, and other forms of loan/lending services to be
            launched by Cryptizi.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>11. Spot Trading</strong> means buying or selling supported Digital Assets and/or any supported fiat
            currency on the exchange for immediate settlement.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>12. Users</strong> refer to all individuals, institutions or organizations that access, download or
            use Cryptizi or Cryptizi Services and who meet the criteria and conditions stipulated by Cryptizi. If there
            exist other agreements for such entities as developers, distributors, market makers, and Digital Currencies
            exchanges, such agreements shall be followed.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            II. General Provisions
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            1. About These Terms
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            a. Contractual Relationship
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: 2, textAlign: 'justify' }}>
            These Terms constitute a legal agreement and create a binding contract between you and Cryptizi Operators.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            b. Supplementary Terms
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Due to the rapid development of Digital Currencies and Cryptizi, these Terms between you and Cryptizi
            Operators do not enumerate or cover all rights and obligations of each party, and do not guarantee full
            alignment with needs arising from future development. Therefore,{' '}
            <strong>
              THE PRIVACY POLICY (（）), CRYPTIZI PLATFORM RULES, AND ALL OTHER AGREEMENTS ENTERED INTO SEPARATELY
              BETWEEN YOU AND CRYPTIZI ARE DEEMED SUPPLEMENTARY TERMS THAT ARE AN INTEGRAL PART OF THESE TERMS AND SHALL
              HAVE THE SAME LEGAL EFFECT. YOUR USE OF CRYPTIZI SERVICES IS DEEMED YOUR ACCEPTANCE OF THE ABOVE
              SUPPLEMENTARY TERMS.
            </strong>
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            c. Changes to These Terms
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi reserves the right to change or modify these Terms in its discretion at any time. Cryptizi will
            notify such changes by updating the terms on its website () and modifying the [Last revised] date displayed
            on this page.{' '}
            <strong>
              ANY AND ALL MODIFICATIONS OR CHANGES TO THESE TERMS WILL BECOME EFFECTIVE UPON PUBLICATION ON THE WEBSITE
              OR RELEASE TO USERS. THEREFORE, YOUR CONTINUED USE OF CRYPTIZI SERVICES IS DEEMED YOUR ACCEPTANCE OF THE
              MODIFIED AGREEMENT AND RULES. IF YOU DO NOT AGREE TO ANY CHANGES TO THESE TERMS, YOU MUST STOP USING
              CRYPTIZI SERVICES IMMEDIATELY. YOU ARE RECOMMENDED TO FREQUENTLY REVIEW THESE TERMS TO ENSURE YOUR
              UNDERSTANDING OF THE TERMS AND CONDITIONS THAT APPLY TO YOUR ACCESS TO AND USE OF CRYPTIZI SERVICES.
            </strong>
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            d. Prohibition of Use
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>
              BY ACCESSING AND USING CRYPTIZI SERVICES, YOU REPRESENT AND WARRANT THAT YOU HAVE NOT BEEN INCLUDED IN ANY
              TRADE EMBARGOES OR ECONOMIC SANCTIONS LIST (SUCH AS THE UNITED NATIONS SECURITY COUNCIL SANCTIONS LIST),
              THE LIST OF SPECIALLY DESIGNATED NATIONALS MAINTAINED BY OFAC (THE OFFICE OF FOREIGN ASSETS CONTROL OF THE
              U.S. DEPARTMENT OF THE TREASURY), OR THE DENIED PERSONS OR ENTITY LIST OF THE U.S. DEPARTMENT OF COMMERCE.
              CRYPTIZI RESERVES THE RIGHT TO CHOOSE MARKETS AND JURISDICTIONS TO CONDUCT BUSINESS AND MAY RESTRICT OR
              REFUSE, AT ITS DISCRETION, THE PROVISION OF CRYPTIZI SERVICES IN CERTAIN COUNTRIES OR REGIONS.
            </strong>
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            2. About Cryptizi
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            As an important part of the Cryptizi Ecosystem, Cryptizi mainly serves as a global online platform for
            Digital Assets trading and provides Users with a trading platform, financing services, technical services
            and other Digital Assets-related services. As further detailed in Article 3 below, Users must register and
            open an account with Cryptizi and deposit Digital Assets into their account prior to trading. Users may,
            subject to the restrictions set forth in these Terms, apply for the withdrawal of Digital Assets.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Although Cryptizi has been committed to maintaining the accuracy of the information provided through
            Cryptizi Services, Cryptizi cannot and does not guarantee its accuracy, applicability, reliability,
            integrity, performance, or appropriateness, nor shall Cryptizi be liable for any loss or damage that may be
            caused directly or indirectly by your use of these contents. The information about Cryptizi Services may
            change without notice, and the main purpose of providing such information is to help Users make independent
            decisions. Cryptizi does not provide investment or consulting advice of any kind, and is not responsible for
            the use or interpretation of information on Cryptizi or any other communication medium. All Users of
            Cryptizi Services must understand the risks involved in Digital Assets trading and are recommended to
            exercise prudence and trade responsibly within their own capabilities.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            3. Cryptizi Account Registration and Requirements
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            a. Registration
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            All Users must apply for a Cryptizi Account at ({' '}
            <Link href='https://cryptizi.com/register' color='primary.main'>
              https://cryptizi.com/register
            </Link>{' '}
            ) before using Cryptizi Services. When you register a Cryptizi Account, you must provide the information
            identified in this paragraph 3 or otherwise as requested by Cryptizi and accept these Terms, the Privacy
            Policy, and other Cryptizi Platform Rules. Cryptizi may refuse, at its discretion, to open a Cryptizi
            Account for you. You agree to provide complete and accurate information when opening a Cryptizi Account and
            agree to timely update any information you provide to Cryptizi to maintain the integrity and accuracy of the
            information. Each User (including natural person, business or legal entity) may maintain only one main
            account at any given time. However, Users can open one or more subaccounts under the main account with the
            consent of Cryptizi. For certain Cryptizi Services, you may be required to set up a specific account
            independent from your Cryptizi Account, based on the provisions of these Terms or the Supplementary Terms.
            The registration, use, protection and management of such trading accounts are equally governed by the
            provisions of this Section and Section VI, unless otherwise stated in these Terms or the Supplementary
            Terms.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            b. Eligibility
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            By registering to use a Cryptizi Account, you represent and warrant that: (i) as an individual, you are at
            least 18 or are of legal age to form a binding contract under applicable laws; (ii) as an individual, legal
            person, or other organization, you have full legal capacity and sufficient authorizations to enter into
            these Terms; (iii) you have not been previously suspended or removed from using the Cryptizi Platform or the
            Cryptizi Services; (iv) you do not have an existing Cryptizi Account; (v) you are not resident, located in
            or otherwise attempting to access the Cryptizi Platform or the Cryptizi Services from, or otherwise acting
            on behalf of a person or legal entity that is resident or located in, a Restricted Location. For the
            purposes hereof, “Restricted Location” shall include the United States, Malaysia, Ontario (Canada), and such
            other locations as designated by Cryptizi Operators from time to time as a “Restricted Location” for the
            purposes hereof; (vi) if you act as an employee or agent of a legal entity, and enter into these Terms on
            their behalf, you represent and warrant that you have all the necessary rights and authorizations to bind
            such legal entity and to access and use the Cryptizi Platform and Cryptizi Services on behalf of such legal
            entity; and (vii) your use of the Cryptizi Platform and the Cryptizi Services will not violate any and all
            laws and regulations applicable to you or the legal entity on whose behalf you are acting, including but not
            limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Please note that there are legal requirements in various countries which may restrict the products and
            services that Cryptizi Operators can lawfully provide. Accordingly, some products and services and certain
            functionality within the Cryptizi Platform may not be available or may be restricted in certain
            jurisdictions or regions or to certain users. You shall be responsible for informing yourself about and
            observing any restrictions and/or requirements imposed with respect to the access to and use of the Cryptizi
            Platform and the Cryptizi Services in each country from which the Cryptizi Platform and the Cryptizi
            Services are accessed by you or on your behalf. Cryptizi Operators reserve the right to change, modify or
            impose additional restrictions with respect to the access to and use of the Cryptizi Platform and/the
            Cryptizi Services from time to time at their discretion at any time without prior notification.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            c. User Identity Verification
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Your registration of an account with Cryptizi will be deemed your agreement to provide required personal
            information for identity verification. Such information will be used to verify Users’ identity, identify
            traces of money laundering, terrorist financing, fraud and other financial crimes through Cryptizi, or for
            other lawful purposes stated by Cryptizi. We will collect, use and share such information in accordance with
            our Privacy Policy. In addition to providing such information, you agree to allow us to keep a record of
            that information during the period for which your account is active and within five (5) years after your
            account is closed, in compliance with global industry standards on data storage. You also authorize us to
            conduct necessary investigations directly or through a third party to verify your identity or protect you
            and/or us from financial crimes, such as fraud. The information we require to verify your identity may
            include, but is not limited to, your name, email address, contact information, phone number, username,
            government-issued ID, date of birth, and other information collected during account registration. When
            providing the required information, you confirm it is true and accurate.{' '}
            <strong>
              AFTER REGISTRATION, YOU MUST ENSURE THAT THE INFORMATION IS TRUE, COMPLETE, AND TIMELY UPDATED WHEN
              CHANGED. IF THERE ARE ANY GROUNDS FOR BELIEVING THAT ANY OF THE INFORMATION YOU PROVIDED IS INCORRECT,
              FALSE, OUTDATED OR INCOMPLETE, CRYPTIZI RESERVES THE RIGHT TO SEND YOU A NOTICE TO DEMAND CORRECTION,
              DIRECTLY DELETE THE RELEVANT INFORMATION, AND, AS THE CASE MAY BE, TERMINATE ALL OR PART OF CRYPTIZI
              SERVICES WE PROVIDE FOR YOU. IF WE ARE UNABLE TO REACH YOU WITH THE CONTACT INFORMATION YOU PROVIDED, YOU
              SHALL BE FULLY LIABLE FOR ANY LOSS OR EXPENSE CAUSED TO CRYPTIZI DURING YOUR USE OF CRYPTIZI SERVICES. YOU
              HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE THE OBLIGATION TO UPDATE ALL THE INFORMATION IF THERE IS ANY
              CHANGE.BY REGISTERING AN ACCOUNT, YOU HEREBY AUTHORIZE CRYPTIZI TO CONDUCT INVESTIGATIONS THAT CRYPTIZI
              CONSIDERS NECESSARY, EITHER DIRECTLY OR THROUGH A THIRD PARTY, TO VERIFY YOUR IDENTITY OR PROTECT YOU,
              OTHER USERS AND/OR CRYPTIZI FROM FRAUD OR OTHER FINANCIAL CRIMES, AND TO TAKE NECESSARY ACTIONS BASED ON
              THE RESULTS OF SUCH INVESTIGATIONS. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL INFORMATION MAY BE
              DISCLOSED TO CREDIT BUREAUS AND AGENCIES FOR FRAUD PREVENTION OR FINANCIAL CRIME PREVENTION, WHICH MAY
              RESPOND TO OUR INVESTIGATIONS IN FULL.
            </strong>
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            d. Account Usage Requirements
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            The Cryptizi Account can only be used by the account registrant. Cryptizi reserves the right to suspend,
            freeze or cancel the use of Cryptizi Accounts by persons other than account registrant. If you suspect or
            become aware of any unauthorized use of your username and password, you should notify Cryptizi immediately.
            Cryptizi assumes no liability for any loss or damage arising from the use of Cryptizi Account by you or any
            third party with or without your authorization.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            e. Account Security
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi has been committed to maintaining the security of User entrusted funds, and has implemented
            industry standard protection for Cryptizi Services. However, the actions of individual Users may pose risks.
            You shall agree to treat your access credentials (such as username and password) as confidential
            information, and not to disclose such information to any third party. You also agree to be solely
            responsible for taking the necessary security measures to protect your Cryptizi Account and personal
            information.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            You should be solely responsible for keeping safe of your Cryptizi Account and password, and be responsible
            for all the transactions under your Cryptizi Account. Cryptizi assumes no liability for any loss or
            consequences caused by authorized or unauthorized use of your account credentials, including but not limited
            to information disclosure, information release, consent or submission of various rules and agreements by
            clicking on the website, online agreement renewal, etc.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            By creating a Cryptizi Account, you hereby agree that:
          </Typography>

          <ol style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                you will notify Cryptizi immediately if you are aware of any unauthorized use of your Cryptizi Account
                and password or any other viliation of security rules;
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                you will strictly abide by all mechanisms or procedures of Cryptizi regarding security, authentication,
                trading, charging, and withdrawal; and
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                you will take appropriate steps to logout from Cryptizi at the end of each visit.
              </Typography>
            </li>
          </ol>

          <Typography variant='h6' sx={{ mt: 3 }}>
            III. Cryptizi Services
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Upon completion of the registration and identity verification for your Cryptizi Account, you may use various
            Cryptizi Services, including but not limited to, Spot Trading, Fiat Services, contract trading, leveraged
            trading, Cryptizi Savings services, staking, acquiring market-related data, research and other information
            released by Cryptizi, participating in User activities held by Cryptizi, etc., in accordance with the
            provisions of these Terms (including Cryptizi Platform Rules and other individual agreements). Cryptizi has
            the right to:
          </Typography>

          <ul style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                Provide, modify or terminate, in its discretion, any Cryptizi Services; and
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Allow or prohibit some Users’ use of any Cryptizi Services in accordance with relevant Cryptizi Platform
                Rules.
              </Typography>
            </li>
          </ul>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi has the exclusive authority to determine which Digital Assets are listed on the platform and may
            add or remove Digital Assets from the platform in its sole discretion, from time to time. Cryptizi may also
            change the order size available for each Digital Asset. In respect of such additions, removals, or
            amendments, Cryptizi may, but is not obliged to, notify Users in advance and Cryptizi shall have no
            liability to Users in connection with such additions, removals or amendments.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            1. Service Usage Guidelines
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            a. License
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Provided that you constantly comply with the express terms and conditions stated in these Terms, Cryptizi
            grants you a revocable, limited, royalty-free, non-exclusive, non-transferable, and non-sublicensable
            license to access and use Cryptizi Services through your computer or Internet compatible devices for your
            personal/internal purposes. You are prohibited to use Cryptizi Services for resale or commercial purposes,
            including transactions on behalf of other persons or entities. All the above actions are expressly
            prohibited and constitute a material violation of these Terms. The content layout, format, function, and
            access rights regarding Cryptizi Services should be stipulated in the discretion of Cryptizi. Cryptizi
            reserves all rights not expressly granted in these Terms. Therefore, you are hereby prohibited from using
            Cryptizi Services in any way not expressly authorized by these Terms.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            These Terms only grant a limited license to access and use Cryptizi Services. Therefore, you hereby agree
            that when you use Cryptizi Services, Cryptizi does not transfer Cryptizi Services or the ownership or
            intellectual property rights of any Cryptizi intellectual property to you or anyone else. All the text,
            graphics, user interfaces, visual interface, photos, sounds, process flow diagrams, computer code (including
            HTML code), programs, software, products, information and documents, as well as the design, structure,
            selection, coordination, expression, look and feel, and layout of any content included in the services or
            provided through Cryptizi Services, are exclusively owned, controlled and/or licensed by Cryptizi Operators
            or its members, parent companies, licensors or affiliates.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi owns any feedback, suggestions, ideas, or other information or materials (hereinafter collectively
            referred to as “Feedback”) about Cryptizi or Cryptizi Services that you provide through email, Cryptizi
            Services, or other ways. You hereby transfer all rights, ownership, and interests of the Feedback and all
            related intellectual property rights to Cryptizi. You have no right and hereby waive any request for
            acknowledgment or compensation based on any Feedback or any modifications based on any Feedback.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            Third-Party’s Copyrights
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            We have integrated{' '}
            <Link href='https://tradingview.com' color='primary.main' target='_blank'>
              TradingView.com
            </Link>{' '}
            API into our website with their consent so that users can access the charts and trading tools. We use
            hyperlinks on our online exchange to redirect users to the TradingView website. We don’t have any
            partnerships or affiliate marketing with TradingView. We do not use any data mining, robots, or similar data
            gathering and extraction tools on the TradingView content, frame any portion of TradingView or its content,
            sublicense, assign, transfer, sell, loan, or otherwise distribute for payment the TradingView content.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            b. Restriction
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            When you use Cryptizi Services, you agree and undertake to comply with the following provisions:
          </Typography>

          <ol style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                During the use of Cryptizi Services, all activities you carry out should comply with the requirements of
                applicable laws and regulations, these Terms, and various guidelines of Cryptizi;
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                Your use of Cryptizi Services should not violate public interests, public morals, or the legitimate
                interests of others, including any actions that would interfere with, disrupt, negatively affect, or
                prohibit other Users from using Cryptizi Services;
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                You agree not to use the services for market manipulation (such as pump and dump schemes, wash trading,
                self-trading, front running, quote stuffing, and spoofing or layering, regardless of whether prohibited
                by law);
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                Without written consent from Cryptizi, the following commercial uses of Cryptizi data are prohibited:
              </Typography>

              <ol style={{ marginLeft: 30 }}>
                <li>
                  <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                    Trading services that make use of Cryptizi quotes or market bulletin board information.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                    Data feeding or streaming services that make use of any market data of Cryptizi.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                    Any other websites/apps/services that charge for or otherwise profit from (including through
                    advertising or referral fees) market data obtained from Cryptizi.
                  </Typography>
                </li>
              </ol>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
                Without prior written consent from Cryptizi, you may not modify, replicate, duplicate, copy, download,
                store, further transmit, disseminate, transfer, disassemble, broadcast, publish, remove or alter any
                copyright statement or label, or license, sub-license, sell, mirror, design, rent, lease, private label,
                grant security interests in the properties or any part of the properties, or create their derivative
                works or otherwise take advantage of any part of the properties.
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
                You may not (i) use any deep linking, web crawlers, bots, spiders or other automatic devices, programs,
                scripts, algorithms or methods, or any similar or equivalent manual processes to access, obtain, copy or
                monitor any part of the properties, or replicate or bypass the navigational structure or presentation of
                Cryptizi Services in any way, in order to obtain or attempt to obtain any materials, documents or
                information in any manner not purposely provided through Cryptizi Services; (ii) attempt to access any
                part or function of the properties without authorization, or connect to Cryptizi Services or any
                Cryptizi servers or any other systems or networks of any Cryptizi Services provided through the services
                by hacking, password mining or any other unlawful or prohibited means; (iii) probe, scan or test the
                vulnerabilities of Cryptizi Services or any network connected to the properties, or violate any security
                or authentication measures on Cryptizi Services or any network connected to Cryptizi Services; (iv)
                reverse look-up, track or seek to track any information of any other Users or visitors of Cryptizi
                Services; (v) take any actions that imposes an unreasonable or disproportionately large load on the
                infrastructure of systems or networks of Cryptizi Services or Cryptizi, or the infrastructure of any
                systems or networks connected to Cryptizi services; (vi) use any devices, software or routine programs
                to interfere with the normal operation of Cryptizi Services or any transactions on Cryptizi Services, or
                any other person’s use of Cryptizi Services; (vii) forge headers, impersonate, or otherwise manipulate
                identification, to disguise your identity or the origin of any messages or transmissions you send to
                Cryptizi, or (viii) use Cryptizi Services in an illegal way.
              </Typography>
            </li>
          </ol>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            By accessing Cryptizi Services, you agree that Cryptizi has the right to investigate any violation of these
            Terms, unilaterally determine whether you have violated these Terms, and take actions under relevant
            regulations without your consent or prior notice. Examples of such actions include, but are not limited to:{' '}
          </Typography>

          <ul style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Blocking and closing order requests;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Freezing your account;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Reporting the incident to the authorities;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Publishing the alleged violations and actions that have been taken;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Deleting any information you published that are found to be violations.
              </Typography>
            </li>
          </ul>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            2. Spot Trading
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            By making use of Spot Trading, you acknowledge and agree that you have read, understood and accepted the
            Spot Trading Terms of Use, which shall apply to all Spot Trading (as such term is defined in the Spot
            Trading Terms of Use).
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            3. Fiat Services
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            3.1 By accepting these Terms, you acknowledge and agree that:
          </Typography>

          <ol style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                you have read, accepted and will comply with these Terms, the Legal Statement, the Privacy Policy, and
                any other Cryptizi Platform Rules related to Fiat Services;
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                Cryptizi may partner with any Fiat Partners and that, if applicable, you will open an account with such
                Fiat Partners by completing the relevant registration and identity verification for such account, and by
                accepting any user agreements, terms and conditions, policies and procedures or similar documents of any
                Fiat Partners;
              </Typography>
            </li>

            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                any payments expressed in any fiat currencies and which have been made in connection with any Fiat
                Services may not be canceled, recalled or refunded, and that any chargeback requests in connection with
                any Fiat Services that involve a Fiat Partner, are subject to that Fiat Partner's policies and
                procedures.
              </Typography>
            </li>
          </ol>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            4. Futures Trading
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Unless otherwise specified by Cryptizi, to conduct Futures Trading, you must conclude with Cryptizi a
            separate Cryptizi Futures Service Agreement and open a special Collateral Account, following the completion
            of registration and identity verification for your Cryptizi Account. You acknowledge and agree that:
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            a. You fully understand the high risks of Futures Trading, including but not limited to the risk of major
            fluctuations of Digital Assets in Futures Trading, and the risk of exacerbated adverse outcome when leverage
            is used;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            b. You have sufficient investment knowledge and experience and the capacity to take risks arising from
            Futures Trading, and agree to independently assume all the risks arising from the investment of Futures
            Trading;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            c. Before performing Futures Trading, you have read and understood all the contents of the Cryptizi Futures
            Service Agreement and the relevant Cryptizi Platform Rules, and have consulted relevant professionals to
            make informed decisions on whether and how to complete Futures Trading according to their recommendations
            and your own reasonable judgment;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            d. You agree and authorize Cryptizi to take various reasonable measures in its discretion (including but not
            limited to forced liquidation and forced position reduction under specific circumstances) in accordance with
            the Cryptizi Futures Service Agreement and the relevant Cryptizi Platform Rules to protect the legitimate
            interests of you, Cryptizi and other Users.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            5. Options Trading
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Before entering into transactions in Options products through Cryptizi (“Options Trading”), you acknowledge
            and agree that:
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            a. You fully understand the high risks of Options Trading, including but not limited to the risk of major
            fluctuations of Digital Assets in Options Trading, and the risk of exacerbated adverse outcome when leverage
            is used;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            b. You have sufficient investment knowledge and experience and the capacity to take risks arising from
            Options Trading, and agree to independently assume all the risks arising from the investment of Options
            Trading;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            c. Before performing Options Trading, you have read and understood, and agree to be bound by the terms of,
            the Cryptizi Options Service Agreement and all relevant Cryptizi Platform Rules, which shall supplement
            these Terms of Use, and have consulted relevant professionals to make informed decisions on whether and how
            to complete Options Trading according to their recommendations and your own reasonable judgment;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            d. You agree and authorize Cryptizi to take various reasonable measures in its discretion (including but not
            limited to forced liquidation and forced position reduction under specific circumstances) in accordance with
            the Cryptizi Options Service Agreement and all relevant Cryptizi Platform Rules to protect the legitimate
            interests of you, Cryptizi and other Users.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            6. Margin Trading
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            By making use of the Margin Services, you acknowledge and agree that you have read, understood and accepted
            the “Margin Service Terms of Use”, which shall apply to all Margin Services (as such term is defined in the
            Margin Service Terms of Use).
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            7. Lending Services
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Unless otherwise provided by Cryptizi, to borrow currencies, you must conclude with Cryptizi a separate
            Lending Services User Agreement and open a special Collateral Account and/or finish other relevant
            procedures, following the completion of registration and identity verification for your Cryptizi Account.
            You understand and agree that:
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            a. There are considerable risks involved in Lending Services, which include without limitation to risks of
            fluctuation of the borrowed Digital Assets’ value, derivative risks and technical risks. You shall carefully
            consider and exercise clear judgment to evaluate your financial situation and the aforesaid risks to make
            any decision on using Lending Services, and you shall be responsible for all losses arising therefrom;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            b. you shall cooperate to provide the information and materials related to identity verification and Lending
            Services as required by Cryptizi, and be solely responsible for taking necessary security measures to
            protect the security of your Collateral Account and personal information;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            c. you shall carefully read relevant Cryptizi Platform Rules before using Lending Services, and be aware of,
            understand and observe the specific information and rules regarding the operations of Lending Services, and
            you undertake that the use of the assets borrowed shall conform to requirements of these Terms and related
            laws and regulations;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            d. Cryptizi has the full right to manage your Collateral Account and collateral during the period in which
            Lending Services are offered, and reserves the right, under the circumstances specified in the Lending
            Services User Agreement or these Terms, to implement various risk control measures, which include but are
            not limited to forced liquidation. Such steps may cause major losses to you and you shall be solely
            responsible for the outcomes of such measures;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            e. Cryptizi has the right to temporarily or permanently prohibit you from using Lending Services when it
            deems it necessary or reasonable, and to the maximum extent permitted by law, without liability of any kind
            to you.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            8. AIO Convert Services
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Convert Services enable Users to buy and sell Digital Assets based on prices provided by Cryptizi. Before
            transacting in Convert Services, you acknowledge and agree that you have read, understood and accepted the
            Convert Terms of Use.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            9. Cryptizi Leveraged Tokens
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Before transacting in Cryptizi Leveraged Tokens, you acknowledge and agree that you have read, understood
            and accepted each of the Cryptizi Leveraged Tokens Risk Disclosure Statement and the Cryptizi Leveraged
            Tokens Agreement, which shall apply to all subscriptions to, redemptions of and transactions in Cryptizi
            Leveraged Tokens (as such term is defined in the Cryptizi Leveraged Tokens Agreement).
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            10. CIZI Conversion
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            A. Deposits
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Unless you opt out of the Designated Stablecoin Conversion, when you deposit or receive a Designated
            Stablecoin into your Cryptizi Account, the amount received will automatically be converted into an
            equivalent amount of CIZI at a rate of 1 to 1.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            B. Withdrawals
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Unless you have opted out of the Designated Stablecoin Conversion, if you have a balance in your Cryptizi
            Account denominated in CIZI, you may give Instructions to withdraw such balance from your Cryptizi Account
            either in CIZI or in any of the Designated Stablecoins. Where you choose to withdraw a Designated
            Stablecoin, you will receive an amount equal to your balance in CIZI, converted into the relevant Designated
            Stablecoin at a rate of 1 to 1, minus any applicable withdrawal and transaction fees.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            C. Exceptions
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi reserves the right to suspend or terminate the Designated Stablecoin Conversion for any or all
            Designated Stablecoins at any time without prior notice. Where necessary due to market conditions affecting
            one or more Designated Stablecoins, Cryptizi may adjust the conversion rate used for the Designated
            Stablecoin Conversion.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            11. Cryptizi OTC
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            If agreed with Cryptizi, you may be permitted to receive certain over-the-counter (OTC) services with
            respect to bilateral transactions in Digital Assets, which services shall be subject to the Cryptizi OTC
            Terms. You agree to be bound by the Cryptizi OTC Terms with respect to all OTC Services provided to you
            through Agreed Communication Channels (as each term is defined in the Cryptizi OTC Terms).
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            1. Disclaimer of Warranties
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>
              TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, CRYPTIZI SERVICES, CRYPTIZI MATERIALS AND ANY
              PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF CRYPTIZI ARE OFFERED ON AN “AS IS” AND “AS
              AVAILABLE” BASIS, AND CRYPTIZI EXPRESSLY DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY
              KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OR WARRANTIES ARISING FROM COURSE OF PERFORMANCE,
              COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING THE FOREGOING, CRYPTIZI DOES NOT REPRESENT OR
              WARRANT THAT THE SITE, CRYPTIZI SERVICES OR CRYPTIZI MATERIALS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
              ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. CRYPTIZI DOES NOT GUARANTEE THAT ANY ORDER
              WILL BE EXECUTED, ACCEPTED, RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS, AGREEMENTS AND
              RULES SET FORTH IN THESE TERMS, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER
              STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF CRYPTIZI SERVICES.
              WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND AGREE THAT CRYPTIZI WILL NOT BE LIABLE FOR ANY
              LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL ASSETS
              PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C) INTERRUPTION IN ANY SUCH DATA,
              (D) REGULAR OR UNSCHEDULED MAINTENANCE CARRIED OUT BY CRYPTIZI AND SERVICE INTERRUPTION AND CHANGE
              RESULTING FROM SUCH MAINTENANCE, (E) ANY DAMAGES INCURRED BY OTHER USERS’ ACTIONS, OMISSIONS OR VIOLATION
              OF THESE TERMS, (F) ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS WITHOUT
              AUTHORIZED BY CRYPTIZI; AND (G) OTHER EXEMPTIONS MENTIONED IN DISCLAIMERS AND PLATFORM RULES ISSUED BY
              CRYPTIZI.
            </strong>
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>
              THE DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF AND TO THE EXTENT IT IS PROHIBITED
              BY APPLICABLE LAW OF THE JURISDICTION IN WHICH YOU RESIDE.
            </strong>
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            2. Disclaimer of Damages and Limitation of Liability
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL CRYPTIZI, ITS AFFILIATES AND THEIR
              RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES,
              SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR
              SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA,
              INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT OF CRYPTIZI SERVICES,
              ANY PERFORMANCE OR NON-PERFORMANCE OF CRYPTIZI SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM
              PROVIDED BY OR ON BEHALF OF CRYPTIZI AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY
              OR OTHER THEORY EVEN IF CRYPTIZI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT
              OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF CRYPTIZI’S GROSS NEGLIGENCE, FRAUD,
              WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            </strong>
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            <strong>
              NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF CRYPTIZI, ITS AFFILIATES AND THEIR
              RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES,
              SUPPLIERS OR CONTRACTORS ARISING OUT OF SERVICES OFFERED BY OR ON BEHALF OF CRYPTIZI AND ITS AFFILIATES,
              ANY PERFORMANCE OR NON-PERFORMANCE OF CRYPTIZI SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM,
              WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID BY
              YOU TO CRYPTIZI UNDER THESE TERMS IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE
              TO THE CLAIM FOR LIABILITY.
            </strong>
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            3. Indemnification
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            You agree to indemnify and hold harmless Cryptizi Operators, their affiliates, contractors, licensors, and
            their respective directors, officers, employees and agents from and against any claims, actions,
            proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines
            or penalties imposed by any regulatory authority) arising out of or related to (i) your use of, or conduct
            in connection with, Cryptizi Services, (ii) your breach or our enforcement of these Terms, or (iii) your
            violation of any applicable law, regulation, or rights of any third party during your use of Cryptizi
            Services. If you are obligated to indemnify Cryptizi Operators, their affiliates, contractors, licensors,
            and their respective directors, officers, employees or agents pursuant to these Terms, Cryptizi will have
            the right, in its sole discretion, to control any action or proceeding and to determine whether Cryptizi
            wishes to settle, and if so, on what terms.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            V. Announcements
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Please be aware that all official announcements, news, promotions, competitions and airdrops will be listed
            on{' '}
            <Link href='www.Cryptizi.com/privacy-policy' color='primary.main'>
              www.cryptizi.com/en/announcement
            </Link>
            . USERS UNDERTAKE TO REFER TO THESE MATERIALS REGULARLY AND PROMPTLY. CRYPTIZI WILL NOT BE HELD LIABLE OR
            RESPONSIBLE IN ANY MANNER OF COMPENSATION SHOULD USERS INCUR PERSONAL LOSSES ARISING FROM IGNORANCE OR
            NEGLIGENCE OF THE ANNOUNCEMENTS.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            VI. Termination of Agreement
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            1. Suspension of Cryptizi Accounts
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            You agree that Cryptizi shall have the right to immediately suspend your Cryptizi Account (and any accounts
            beneficially owned by related entities or affiliates), freeze or lock the Digital Assets or funds in all
            such accounts, and suspend your access to Cryptizi for any reason including if Cryptizi suspects any such
            accounts to be in violation of these Terms, our Privacy Policy, or any applicable laws and regulations. You
            agree that Cryptizi shall not be liable to you for any permanent or temporary modification of your Cryptizi
            Account, or suspension or termination of your access to all or any portion of Cryptizi Services. Cryptizi
            shall reserve the right to keep and use the transaction data or other information related to such Cryptizi
            Accounts. The above account controls may also be applied in the following cases:
          </Typography>

          <ul style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                The Cryptizi Account is subject to a governmental proceeding, criminal investigation or other pending
                litigation;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                We detect unusual activities in the Cryptizi Account;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                We detect unauthorized access to the Cryptizi Account;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                We are required to do so by a court order or command by a regulatory/government authority.
              </Typography>
            </li>
          </ul>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            2. Cancellation of Cryptizi Accounts
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            In case of any of the following events, Cryptizi shall have the right to directly terminate these Terms by
            cancelling your Cryptizi Account, and shall enjoy the right but not the obligation to permanently freeze
            (cancel) the authorizations of your Cryptizi Account on Cryptizi and withdraw the corresponding Cryptizi
            Account thereof:
          </Typography>

          <ul style={{ marginLeft: 50 }}>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 2, textAlign: 'justify' }}>
                after Cryptizi terminates services to you;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                you allegedly register or register in any other person’s name as a Cryptizi User again, directly or
                indirectly;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                the information that you have provided is untruthful, inaccurate, outdated or incomplete;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                when these Terms are amended, you state your unwillingness to accept the amended Terms by applying for
                cancellation of your Cryptizi Account or by other means;
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                you request that Cryptizi Services be terminated; and
              </Typography>
            </li>
            <li>
              <Typography variant='body2' sx={{ lineHeight: 2, mt: 1, textAlign: 'justify' }}>
                any other circumstances where Cryptizi deems it should terminate Cryptizi Services.
              </Typography>
            </li>
          </ul>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Should your Cryptizi Account be terminated, the account and transactional information that meet data
            retention standards will be securely stored for 5 years. In addition, if a transaction is unfinished during
            the account termination process, Cryptizi shall have the right to notify your counterparty of the situation
            at that time. You acknowledge that a user-initiated account exit (right to erasure under GDPR or other
            equivalent regulations) will also be subjected to the termination protocol stated above.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            If Cryptizi is informed that any Digital Assets or funds held in your Cryptizi Account are stolen or
            otherwise are not lawfully possessed by you, Cryptizi may, but has no obligation to, place an administrative
            hold on the affected funds and your Cryptizi Account. If Cryptizi does lay down an administrative hold on
            some or all of your funds or Cryptizi Account, Cryptizi may continue such hold until such time as the
            dispute has been resolved and evidence of the resolution acceptable to Cryptizi has been provided to
            Cryptizi in a form acceptable to Cryptizi. Cryptizi will not involve itself in any such dispute or the
            resolution of the dispute. You agree that Cryptizi will have no liability or responsibility for any such
            hold, or for your inability to withdraw Digital Assets or funds or execute trades during the period of any
            such hold.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            3. Remaining Funds After Cryptizi Account Termination
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Except as set forth in paragraph 4 below, once a Cryptizi Account is closed/withdrawn, all remaining account
            balance (which includes charges and liabilities owed to Cryptizi) will be payable immediately to Cryptizi.
            Upon payment of all outstanding charges to Cryptizi (if any), Users will have 5 business days to withdraw
            all Digital Assets or funds from the account.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4. Remaining Funds After Cryptizi Account Termination Due to Fraud, Violation of Law, or Violation of These
            Terms
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi maintains full custody of the Digital Assets, funds and User data/information which may be turned
            over to governmental authorities in the event of Cryptizi Accounts’ suspension/closure arising from fraud
            investigations, investigations of violation of law or violation of these Terms.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            5. Dormant Accounts
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Notwithstanding any provision of this Section VI, Cryptizi may provide a written notice requiring you to
            close all of your open positions and withdraw all of your Digital Assets from your Cryptizi Account within
            30 days of the notice. In the event that you fail to do so, Cryptizi may in its absolute discretion and
            without prior notice to you: (a) deem your Cryptizi account as a dormant account;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (b) close any open positions in any Cryptizi products;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (c) convert the Digital Assets to a different type of Digital Asset (e.g., from BTC to CIZI). For the
            avoidance of doubt, none of the Cryptizi Operators shall be liable for any loss of profit, tax obligations
            or any other loss, damage or expense incurred by you resulting from such conversion;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (d) transfer such dormant account (including any Digital Assets contained therein) to an affiliate of the
            Cryptizi Operators, any third-party custodian or an isolated wallet where it is deemed reasonably necessary
            by Cryptizi to do so. In the event that such transfer has taken place, you have the right to retrieve your
            digital assets from subject to satisfying Cryptizi’s verification requirements, including completing KYC;
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (e) charge a dormant account fee to cover the cost of maintaining the assets by the Cryptizi Operators, its
            affiliates or any third-party and such fee shall be withdrawn directly from the dormant account on a monthly
            basis; and
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            (f) close a dormant account at any time, and Cryptizi will not be liable for any loss, damage or expense
            incurred by you as a result of the closure of a dormant account unless there was fraud or willful default by
            Cryptizi. Any assets in these dormant accounts will be transferred in accordance to paragraph 5(d) above.
            After a dormant account is closed, it cannot be reactivated by you (i.e. you will need to register a new
            Cryptizi account if you wish to continue to use Cryptizi Services).
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            VII. No Financial Advice
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Cryptizi is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation
            to you in connection with any trades or other decisions or activities effected by you using Cryptizi
            Services. No communication or information provided to you by Cryptizi is intended as, or shall be considered
            or construed as, investment advice, financial advice, trading advice, or any other sort of advice. Unless
            otherwise specified in these Terms, all trades are executed automatically, based on the parameters of your
            order instructions and in accordance with posted trade execution procedures, and you are solely responsible
            for determining whether any investment, investment strategy or related transaction is appropriate for you
            according to your personal investment objectives, financial circumstances and risk tolerance, and you shall
            be solely responsible for any loss or liability therefrom. You should consult legal or tax professionals
            regarding your specific situation. Cryptizi does not recommend that any Digital Asset should be bought,
            earned, sold, or held by you. Before making the decision to buy, sell or hold any Digital Asset, you should
            conduct your own due diligence and consult your financial advisors prior to making any investment decision.
            Cryptizi will not be held responsible for the decisions you make to buy, sell, or hold Digital Asset based
            on the information provided by Cryptizi.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            VIII. Compliance with Local Laws
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            It is Users’ responsibility to abide by local laws in relation to the legal usage of Cryptizi Services in
            their local jurisdiction as well as other laws and regulations applicable to Users. Users must also factor,
            to the extent of their local laws all aspects of taxation, the withholding, collection, reporting and
            remittance to their appropriate tax authorities.{' '}
            <strong>
              ALL USERS OF CRYPTIZI SERVICES ACKNOWLEDGE AND DECLARE THAT THEIR FUNDS COME FROM LEGITIMATE SOURCES AND
              DO NOT ORIGINATE FROM ILLEGAL ACTIVITIES; USERS AGREE THAT CRYPTIZI WILL REQUIRE THEM TO PROVIDE OR
              OTHERWISE COLLECT THE NECESSARY INFORMATION AND MATERIALS AS PER RELEVANT LAWS OR GOVERNMENT ORDERS TO
              VERIFY THE LEGALITY OF THE SOURCES AND USE OF THEIR FUNDS.
            </strong>{' '}
            Cryptizi maintains a stance of cooperation with law enforcement authorities globally and will not hesitate
            to seize, freeze, terminate Users’ accounts and funds which are flagged out or investigated by legal
            mandate.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            IX. Privacy Policy
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            Access to Cryptizi Services will require the submission of certain personally identifiable information.
            Please review Cryptizi’s Privacy Policy at{' '}
            <Link href='www.Cryptizi.com/privacy-policy' color='primary.main'>
              www.cryptizi.com/privacy-policy
            </Link>{' '}
            for a summary of Cryptizi’s guidelines regarding the collection and use of personally identifiable
            information.
          </Typography>

          <Typography variant='subtitle1' sx={{ mt: 3 }}>
            X. Resolving Disputes: Forum, Arbitration, Class Action Waiver
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            PLEASE READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS,
            INCLUDING AS A CLASS ACTION.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            1. Notice of Claim and Dispute Resolution Period. Please contact Cryptizi first! Cryptizi wants to address
            your concerns without resorting to formal legal proceedings, if possible. If you have a dispute with
            Cryptizi, then you should contact Cryptizi and a ticket number will be assigned. Cryptizi will attempt to
            resolve your dispute internally as soon as possible. The parties agree to negotiate in good faith to resolve
            the dispute (which discussions shall remain confidential and be subject to applicable rules protecting
            settlement discussions from use as evidence in any legal proceeding). In the event the dispute cannot be
            resolved satisfactorily, and you wish to assert a legal claim against Cryptizi, then you agree to set forth
            the basis of such claim in writing in a “Notice of Claim,” as a form of prior notice to Cryptizi. The Notice
            of Claim must (1) describe the nature and basis of the claim or dispute, (2) set forth the specific relief
            sought, (3) provide the original ticket number, and (4) include your Cryptizi account email. The Notice of
            Claim should be submitted to an email address or hyperlink provided in your correspondence with Cryptizi.
            After you have provided the Notice of Claim to Cryptizi, the dispute referenced in the Notice of Claim may
            be submitted by either Cryptizi or you to arbitration in accordance with paragraph 2 of this Section, below.
            For the avoidance of doubt, the submission of a dispute to Cryptizi for resolution internally and the
            delivery of a Notice of Claim to Cryptizi are prerequisites to commencement of an arbitration proceeding (or
            any other legal proceeding). During the arbitration, the amount of any settlement offer made by you or
            Cryptizi shall not be disclosed to the arbitrator.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            2. Agreement to Arbitrate and Governing Law. You and Cryptizi Operators agree that, subject to paragraph 1
            above, any dispute, claim, or controversy between you and Cryptizi (and/or Cryptizi Operators) arising in
            connection with or relating in any way to these Terms or to your relationship with Cryptizi (and/or Cryptizi
            Operators) as a user of Cryptizi Services (whether based in contract, tort, statute, fraud,
            misrepresentation, or any other legal theory, and whether the claims arise during or after the termination
            of these Terms) will be determined by mandatory final and binding individual (not class) arbitration, except
            as set forth below under Exceptions to Agreement to Arbitrate. You and Cryptizi Operators further agree that
            the arbitrator shall have the exclusive power to rule on his or her own jurisdiction, including without
            limitation any objections with respect to the existence, scope or validity of the Agreement to Arbitrate, or
            to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court.
            <strong>
              THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
            </strong>{' '}
            There may be more limited discovery than in court. The arbitrator must follow this agreement and can award
            the same damages and relief as a court (including, if applicable, attorney fees), except that the arbitrator
            may not award declaratory or injunctive relief in favour of anyone but the parties to the arbitration. The
            arbitration provisions set forth in this Section will survive termination of these Terms. Arbitration Rules.
            The arbitration shall be subject to the HKIAC Administered Arbitration Rules (HKIAC. Rules) in force when
            the Notice of Arbitration is submitted, as modified by this Section X. The arbitration will be administered
            by the Hong Kong International Arbitration Centre (HKIAC). Unless the parties agree otherwise, there shall
            be only one arbitrator appointed in accordance with the HKIAC Rules. Any arbitration will be conducted in
            the English language. Regardless of the manner in which the arbitration is conducted, the arbitrator shall
            issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the
            decision and award, if any, are based.{' '}
            <strong>
              JUDGMENT ON ANY ARBITRAL AWARD MAY BE GIVEN IN ANY COURT HAVING JURISDICTION OVER THE PARTY (OR OVER THE
              ASSETS OF THE PARTY) AGAINST WHOM SUCH AN AWARD IS RENDERED. Time for Filing: ANY ARBITRATION AGAINST
              CRYPTIZI OPERATORS MUST BE COMMENCED BY FILING A REQUEST FOR ARBITRATION WITHIN ONE (1) YEAR, AFTER THE
              DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION OR DEFAULT
              GIVING RISE TO THE CLAIM; AND THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT
              TIME PERIOD. THIS ONE YEAR LIMITATION PERIOD IS INCLUSIVE OF THE INTERNAL DISPUTE RESOLUTION PROCEDURE SET
              FORTH IN PARAGRAPH 1 OF THIS SECTION, ABOVE. THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT
              ASSERTED WITHIN THAT TIME PERIOD.
            </strong>{' '}
            If applicable law prohibits a one-year limitation period for asserting claims, any claim must be asserted
            within the shortest time period permitted by applicable law. Process; Notice: The party who intends to seek
            arbitration after the expiration of the Dispute Resolution Period set forth in paragraph 1, above, must
            submit a request to the HKIAC in accordance with the HKIAC Rules. If we request arbitration against you, we
            will give you notice at the email address or mailing address you have provided. You agree that any notice
            sent to this email or mailing address shall be deemed effective for all purposes, including without
            limitation to determinations of adequacy of service. It is your obligation to ensure that the email address
            and/or mailing address on file with Cryptizi is up-to-date and accurate. Seat of Arbitration: The seat of
            the arbitration shall be Hong Kong. Place of Hearing: The location of any in-person arbitration hearing
            shall be Hong Kong, unless otherwise agreed to by the parties. Governing Law: These Terms (including this
            arbitration agreement) shall be governed by, and construed in accordance with, the laws of Hong Kong.
            Confidentiality. The parties agree that the arbitration shall be kept confidential. The existence of the
            arbitration, any nonpublic information provided in the arbitration, and any submissions, orders or awards
            made in the arbitration (together, the “Confidential Information”) shall not be disclosed to any non-party
            except the tribunal, the HKIAC, the parties, their counsel, experts, witnesses, accountants and auditors,
            insurers and reinsurers, and any other person necessary to the conduct of the arbitration. Notwithstanding
            the foregoing, a party may disclose Confidential Information to the extent that disclosure may be required
            to fulfill a legal duty, protect or pursue a legal right, or enforce or challenge an award in bona fide
            legal proceedings. This confidentiality provision shall survive termination of these Terms and of any
            arbitration brought pursuant to these Terms.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3. Class Action Waiver. You and Cryptizi agree that any claims relating to these Terms or to your
            relationship with Cryptizi as a user of Cryptizi Services (whether based in contract, tort, statute, fraud,
            misrepresentation, or any other legal theory, and whether the claims arise during or after the termination
            of these Terms) shall be brought against the other party in an arbitration on an individual basis only and
            not as a plaintiff or class member in a purported class or representative action. You and Cryptizi further
            agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective,
            representative, or private attorney general action, to the extent permissible by applicable law. Combining
            or consolidating individual arbitrations into a single arbitration is not permitted without the consent of
            all parties, including Cryptizi.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4. Modifications. Cryptizi reserves the right to update, modify, revise, suspend, or make any future changes
            to Section X regarding the parties’ Agreement to Arbitrate, subject to applicable law. You hereby consent
            and agree that it is your responsibility to ensure that your understanding of this Section is up to date.
            Subject to the applicable law, your continued use of your Cryptizi account shall be deemed to be your
            acceptance of any modifications to Section X regarding the parties’ Agreement to Arbitrate. You agree that
            if you object to the modifications to Section X, Cryptizi may block access to your account pending closure
            of your account. In such circumstances, the Terms of Use prior to modification shall remain in full force
            and effect pending closure of your account.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5. Severability. If any portion of these Terms are adjudged to be invalid or unenforceable for any reason or
            to any extent, the remainder of these Terms will remain valid and enforceable and the invalid or
            unenforceable portion will be given effect to the greatest extent permitted by law. pending closure of your
            account.
          </Typography>

          <Typography variant='h6' sx={{ mt: 3 }}>
            XI. Miscellaneous
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            1. Independent Parties. Cryptizi is an independent contractor but not an agent of you in the performance of
            these Terms. These Terms shall not be interpreted as facts or evidence of an association, joint venture,
            partnership, or franchise between the parties.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            2. Entire Agreement. These Terms constitute the entire agreement between the parties regarding use of
            Cryptizi Services and will supersede all prior written or oral agreements between the parties. No usage of
            trade or other regular practice or method of dealing between the parties will be used to modify, interpret,
            supplement, or alter the terms herein.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            3. Interpretation and Revision. Cryptizi reserves the right to alter, revise, modify, and/or change these
            Terms at any time. All changes will take effect immediately upon being published on Cryptizi websites. It is
            your responsibility to regularly check relevant pages on our websites/applications to confirm the latest
            version of these Terms. If you do not agree to any such modifications, your only remedy is to terminate your
            usage of Cryptizi Services and cancel your account. You agree that, unless otherwise expressly provided in
            these Terms, Cryptizi will not be responsible for any modification or termination of Cryptizi Services by
            you or any third party, or suspension or termination of your access to Cryptizi Services.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            4. Language & Translations: These Terms may, at Cryptizi’s sole and absolute discretion, be translated into
            a language other than the English language. You agree that any such translation shall only be for your
            convenience and the English text shall prevail in the event of any ambiguity, discrepancy or omission as
            between the English text and any translated text.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            5. Force Majeure. Cryptizi will not be liable for any delay or failure to perform as required by these Terms
            because of any cause or condition beyond Cryptizi’s reasonable control.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            6. Severability. If any portion of these Terms is held invalid or unenforceable, such invalidity or
            enforceability will not affect the other provisions of these Terms, which will remain in full force and
            effect, and the invalid or unenforceable portion will be given effect to the greatest extent possible.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            7. Assignment. You may not assign or transfer any right to use Cryptizi Services or any of your rights or
            obligations under these Terms without prior written consent from Cryptizi, including any right or obligation
            related to the enforcement of laws or the change of control. Cryptizi may assign or transfer any or all of
            its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent
            or approval.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            8. Waiver. The failure of one party to require performance of any provision will not affect that party’s
            right to require performance at any time thereafter. At the same time, the waiver of one party to seek
            recovery for the other party’s violation of these Terms or any provision of applicable terms shall not
            constitute a waiver by that party of any subsequent breach or violation by the other party or of the
            provision itself.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            9. Third-Party Website Disclaimer. Any links to third-party websites from Cryptizi Services does not imply
            endorsement by Cryptizi of any product, service, information or disclaimer presented therein, nor does
            Cryptizi guarantee the accuracy of the information contained on them. If you suffer loss from using such
            third-party product and service, Cryptizi will not be liable for such loss. In addition, since Cryptizi has
            no control over the terms of use or privacy policies of third-party websites, you should read and understand
            those policies carefully.
          </Typography>

          <Typography variant='body2' sx={{ lineHeight: 2, mt: 3, textAlign: 'justify' }}>
            10. Matters Related to Apple Inc. If you use any device manufactured by Apple Inc. to participate in any
            commercial activities or reward programs through Cryptizi Services, such activities, and programs are
            provided by Cryptizi and are not associated with Apple Inc. in any manner.
          </Typography>

          <Divider sx={{ mt: 8, mb: -6 }} />
        </Container>

        <Footer />
      </RootStyle>
    </Stack>
  );
}

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  marginTop: 130,

  direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
}));
