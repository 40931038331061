import {alpha, Box, Card, Grid, Tab, Tabs, useTheme} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../store/actionTypes';
import RoundChart from './roundChart';
import LineChart from './lineChart';
import StatisticChart from './statisticChart';
import TradeChart from './tradeChart';
import { useState } from 'react';
import UserStatisticsTab from './tabs/userStatisticsTab';
import DepositWithdrawStatisticsTab from './tabs/depositWithdrawStatisticsTab';
import SuccessfulTradesTab from './tabs/successfulTradesTab';
import FuturesOpenPositionTab from './tabs/futuresOpenPositionTab';
import CryptoStatisticsTab from './tabs/cryptoStatisticsTab';

const AdminReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Reporting' },
    });
  }, []);

  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };
  const theme = useTheme();

  return (
    <Box sx={{ my: 3, mx: { xs: 2, sm: 0 } }}>
      <Box className='no-print'>
        <StatisticChart />
      </Box>

      <Box className='no-print'>
        <TradeChart />
      </Box>

      <Grid container spacing={2} className='no-print'>
        <Grid item xs={12} md={5}>
          <RoundChart />
        </Grid>
        <Grid item xs={12} md={7}>
          <LineChart />
        </Grid>
      </Grid>

      <div className='full-width-print'>
        <Card sx={{ mt: 2 }}>
          <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'   sx={{
              '& .Mui-selected': {
                color: 'primary.main', // Set the selected tab color to primary
                backgroundColor: alpha(theme.palette.primary.main, 0.2)

              },
              '& .MuiTabs-indicator': {
                backgroundColor: 'primary.main', // Set the indicator color to primary
              },
            }}>
              <Tab label={t('User Statistics')} sx={{ width: 160 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Deposit Statistics')} sx={{ width: 190 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Withdraw Statistics')} sx={{ width: 200 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Successful Orders')} sx={{ width: 170 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Unsuccessful Orders')} sx={{ width: 170 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Open Orders')} sx={{ width: 165 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Futures Open position')} sx={{ width: 200 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
              <Tab label={t('Crypto Statistics')} sx={{ width: 200 ,borderBottom: `2px solid ${theme.palette.grey[400]}`,
            '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <UserStatisticsTab />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {/* <DepositStatisticsTab /> */}
            <DepositWithdrawStatisticsTab section='deposit' />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <DepositWithdrawStatisticsTab section='withdraw' />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <SuccessfulTradesTab section='successful' />
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <SuccessfulTradesTab section='unSuccessful' />
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <SuccessfulTradesTab section='open' />
          </TabPanel>
          <TabPanel value={tab} index={6}>
            <FuturesOpenPositionTab />
          </TabPanel>
          <TabPanel value={tab} index={7}>
            <CryptoStatisticsTab />
          </TabPanel>
        </Card>
      </div>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 2.5 }}>{children}</Box>}
    </div>
  );
}

export default AdminReport;
