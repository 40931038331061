import React from 'react';
import { Image, Row, Col } from 'antd';
import classes from './forgotNewPass.module.scss';
import logo from '../../../../assets/l_logo.png';
import ForgotNewPassForm from './forgotNewPassForm';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import {useTheme} from "@mui/material";
import logo_dark from "../../../../assets/l_logo_dark.png";

const ForgotNewPass = () => {
  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image src={theme?.palette?.mode === 'dark' ? logo :logo_dark} sx={{ width: 'auto', height : 24  }} />

          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='center'>
        <Col {...colPropsForm}>
          <ForgotNewPassForm />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotNewPass;
