import { useEffect, useState } from 'react';
import {alpha, Box, Card, CircularProgress, Stack, Tab, Tabs, useTheme} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { PageTitle } from '../../../../components/PageTitle';

import useLevelSettingSubmit from './hooks/useLevelSettingSubmit';
import useLevelSettingForm from './hooks/useLevelSettingForm';

import UserLevelSettingForm from './userLevelSettingForm';

export default function UserLevelSetting() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // ----------------
  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'User level settings' },
    });
  }, []);

  // ----------------
  const [data, setData] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState('BASIC');

  // ----------------
  const fetchData = async () => {
    setData(null);

    try {
      const response = await Api.getUserLevelSetting(selectedLevel);
      if (response?.status === 'SUCCESS') {
        setData(response);
      }
    } catch (e) {}
  };

  // ----------------
  useEffect(() => {
    fetchData();
  }, [selectedLevel]);

  // ----------------
  const { onSubmit } = useLevelSettingSubmit(selectedLevel);
  const { handleSubmit, isSubmitting, methods } = useLevelSettingForm(data);
  const theme = useTheme();

  // ----------------
  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Card>
        <PageTitle
          title='User level settings'
          subTitle='User level settings can be changed from this section, and please change the settings of this section carefully.'
        />

        <Box sx={{ width: { xs: '100%', md: '90%', lg: '75%', xl: '60%' } }}>
          <Box sx={{ borderBottom: 2, borderColor: 'divider', mt: 5 }}>
            <Tabs value={selectedLevel} onChange={(e, newValue) => setSelectedLevel(newValue)}   sx={{
              '& .Mui-selected': {
                color: 'primary.main', // Set the selected tab color to primary
                backgroundColor: alpha(theme.palette.primary.main, 0.2)

              },
              '& .MuiTabs-indicator': {
                backgroundColor: 'primary.main', // Set the indicator color to primary
              },
            }}>
              {['REGISTERED', 'BASIC', 'INTERMEDIATE', 'ADVANCED'].map((item) => (
                <Tab
                  key={item}
                  value={item}
                  label={t(item === 'REGISTERED' ? 'UnVerified' : item)}
                  sx={{ width: { xs: 'auto', sm: '25%', minWidth: { xs: 70, sm: 'auto' } }, typography: 'body3' }}
                />
              ))}
            </Tabs>
          </Box>

          {data === null ? (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 300 }}>
              <CircularProgress size={30} color='success' />
            </Stack>
          ) : (
            <>
              <UserLevelSettingForm onSubmit={handleSubmit(onSubmit)} methods={methods} isSubmitting={isSubmitting} />
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
}
