import {Box, Button, Card, Tab, Tabs, useTheme,alpha} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import useWallet from '../../../../hooks/useWallet';
import P2PTab from './p2pTab';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { navigationUrl } from '../../../../navigationUrl';
import Api from '../../../../http/serviceApi';
import StatusModal from '../../../../components/StatusModal';
import PaymentMethod from '../../../../components/PaymentMethod';
import { LoadingButton } from '@mui/lab';

export default function P2PRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Post New Ad' },
    });
  }, []);

  const { userWallet } = useWallet();

  const [tab, setTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [side, setSide] = useState('buy');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [symbol, setSymbol] = useState('USDT');
  const [fiat, setFiat] = useState('USD');
  const [maxLimit, setMaxLimit] = useState('');
  const [minLimit, setMinLimit] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [paymentMethod, setSelectPayment] = useState('WALLET');

  const handleTabChange = (e, newTab) => {
    setTab(newTab);

    if (newTab === 0) {
      setSide('buy');
    } else {
      setSide('sell');
    }
  };

  const onSubmit = async () => {
    if (+amount === 0) {
      return enqueueSnackbar(t('Please enter total amount!'), { variant: 'warning' });
    }
    if (+price === 0) {
      return enqueueSnackbar(t('Please enter price!'), { variant: 'warning' });
    }
    if (+minLimit === 0) {
      return enqueueSnackbar(t('Please enter minimum order limit!'), { variant: 'warning' });
    }

    setIsSubmitting(true);

    try {
      const data = await Api.requestP2POrder({
        side: side === 'buy' ? 'SELL' : 'BUY',
        amount: +amount,
        minLimit: +minLimit / +price,
        maxLimit: +maxLimit / +price,
        symbol: symbol,
        usdPrice: +price,
      });

      if (data.status === 'SUCCESS') {
        setOpenSuccessModal(true);
      }
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <>
      <Box sx={{ position: 'relative', mt: 7 }}>
        <Button
          variant='outlined'
          color='inherit'
          component={Link}
          to={navigationUrl.p2p_list}
          sx={{
            position: 'absolute',
            right: { xs: 25, md: 0 },
            top: { xs: -60, md: 0 },
            zIndex: 1,
            borderColor: 'grey.400',
            typography: 'body3',
            height: 40,
            width: 150,
          }}
        >
          {t('Ads List')}
        </Button>

        <Box>
          <Tabs
            centered
            value={tab}
            onChange={handleTabChange}
            aria-label='basic tabs'
            textColor='inherit'
            indicatorColor='secondary'
          >
            <Tab label={t('Buy')} sx={{ width: 200,borderBottom: `2px solid ${theme.palette.grey[400]}`,
              '&.Mui-selected': {
                color: theme.palette.primary.main, // Set the selected tab color to primary
                backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
              },}} />
            <Tab label={t('Sell')} sx={{ width: 200,borderBottom: `2px solid ${theme.palette.grey[400]}`,
              '&.Mui-selected': {
              color: theme.palette.primary.main, // Set the selected tab color to primary
              backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
            },}} />
          </Tabs>
        </Box>

        <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
          <Box sx={{ width: { sx: '100%', md: 627 }, m: 'auto' }}>
            <TabPanel value={tab} index={0}>
              <P2PTab
                side='buy'
                symbol={symbol}
                fiat={fiat}
                onChangeSymbol={setSymbol}
                onChangeFiat={setFiat}
                amount={amount}
                price={price}
                onChangeAmount={setAmount}
                onChangePrice={setPrice}
                userWallet={userWallet}
                maxLimit={maxLimit}
                onChangeMaxLimit={setMaxLimit}
                minLimit={minLimit}
                onChangeMinLimit={setMinLimit}
              />
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <P2PTab
                side='sell'
                symbol={symbol}
                fiat={fiat}
                onChangeSymbol={setSymbol}
                onChangeFiat={setFiat}
                amount={amount}
                price={price}
                onChangeAmount={setAmount}
                onChangePrice={setPrice}
                userWallet={userWallet}
                maxLimit={maxLimit}
                onChangeMaxLimit={setMaxLimit}
                minLimit={minLimit}
                onChangeMinLimit={setMinLimit}
              />
            </TabPanel>

            <PaymentMethod
              onSelectGateway={setSelectPayment}
              paymentMethod={paymentMethod}
              disableItems={['DEBIT', 'SHETAB', 'FAST_PAY']}
            />

            <LoadingButton
              variant='contained'
              size='large'
              fullWidth
              onClick={onSubmit}
              sx={{ mt: 7 }}
              loading={isSubmitting}
            >
              {t('Post')}
            </LoadingButton>
          </Box>
        </Card>
      </Box>

      <StatusModal
        open={openSuccessModal}
        onClose={() => navigate(navigationUrl.p2p_list)}
        onAction={() => navigate(navigationUrl.p2p_list)}
        delay={0}
        buttonLabel='Close'
      />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  if (value === index) {
    return <Box sx={{ mb: 5 }}>{children}</Box>;
  }
}
