import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useTable from '../../../../hooks/useTable';
import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { Button, Card, Stack } from '@mui/material';
import { navigationUrl } from '../../../../navigationUrl';
import { PageTitle } from '../../../../components/PageTitle';
import TokenTable from './tokenTable';
import Api from '../../../../http/adminHttp/serviceApiAdmin';
import { ViewTokenModal } from './viewTokenModal';
import { EditTokenModal } from './editTokenModal';
import TokenToolbar from './tokenToolbar';
import { getDefaultDateFilterValues } from '../../../../utils/filter';
import ModalContainer from "../../../../components/ModalContainer";

export const TokenList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [editModal, setEditModal] = useState(null);
  const [viewModal, setViewModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);

  const [filters, setFilters] = useState({
    startTime: getDefaultDateFilterValues().firstDay,
    endTime: getDefaultDateFilterValues().lastDay,
    name: '',
    symbol: '',
    active: undefined,
  });

  const getData = async () => {
    try {
      for (let key in filters) {
        !filters[key] && typeof filters[key] !== 'boolean' && (filters[key] = undefined);
      }
      const response = await Api.getTokenList({
        ...filters,
        startTime: filters.startTime?.valueOf?.(),
        endTime: filters.endTime?.valueOf?.(),
      });
      setTableData(response.listedTokenInfoList);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Tokens' },
    });
  }, []);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const deleteTokenhandler = async  (token) =>{
    try {
      const d = await Api.deleteToken(token?.symbol)
        getData()
        setDeleteModal(null)
    }catch (e) {

    }
  }
  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' }, mt: 0.5 }}>
      <Button
        variant='contained'
        onClick={() => navigate(navigationUrl.admin_token_add)}
        size='large'
        sx={{
          maxWidth: 150,
          my: 2.5,
        }}
        className='no-print'
      >
        {t('Add Token')}
      </Button>

      <Card sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: 5 } }}>
        <PageTitle title='Token' subTitle='You can not disable a port that is in the default state.' />

        <TokenToolbar filters={filters} setFilters={setFilters} onFilter={getData} />

        <TokenTable
          tableData={tableData}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          handleEdit={setEditModal}
          handleView={setViewModal}
          handleDelete={setDeleteModal}

        />
      </Card>

      {editModal && <EditTokenModal open={!!editModal} data={editModal} onClose={() => setEditModal(null)} />}
      {viewModal && <ViewTokenModal open={!!viewModal} data={viewModal} onClose={() => setViewModal(null)} />}
      <ModalContainer open={!!deleteModal} onClose={()=>setDeleteModal(null)} hasCloseButton={true}>
           <h3>
             {t("Do you want to delete ") + deleteModal?.symbol+'?'}
           </h3>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
            <Button
                fullWidth
                size='large'
                color='success'
                variant='contained'
                sx={{ typography: 'body3', fontWeight: 500, maxWidth: '50%' }}
                onClick={()=>setDeleteModal(null)}
            >
                {t('No')}
            </Button>
            <Button
                fullWidth

                size='large'
                color='error'
                variant='contained'
                sx={{ typography: 'body3', fontWeight: 500, maxWidth: '50%' }}
                onClick={()=>deleteTokenhandler(deleteModal)}
            >
                {t('Yes')}
            </Button>
        </Stack>
      </ModalContainer>
    </Stack>
  );
};
