import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}
function createHorizontalGradient(color1, color2, color3) {
  return `linear-gradient(to left, ${color1}, ${color2}, ${color3})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#9AFBFC',  // Lightest tone, derived from lightening #23F4F9
  light: '#62F9FB',    // Light tone, between lighter and main
  main: '#23F4F9',     // Main color
  dark: '#1BC4C7',     // Darker tone, derived from darkening #23F4F9
  darker: '#159A9D',   // Darkest tone, further darkened
};

const SECONDARY = {
  lighter: '#CC6AFF',
  light: '#CC6AFF',
  main: '#C253FD',
  dark: '#BB3DFD',
  darker: '#BB3DFD',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  darkest: '#252D55',
};
const SUCCESS = {
  lighter: '#D0FCFD', // a lighter shade of #23F4F9
  light: '#5DF6F9',   // a lighter shade that complements the main color
  main: '#23F4F9',    // the main color as per your request
  dark: '#1ECACD',    // a darker shade of the main color
  darker: '#087A7D',  // the darkest shade for a deep contrast
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#FA2256',
};

const GREY = {
  0: '#FFFFFF',
  100: '#E5E6ED',
  200: '#E5E6ED',
  250: '#C4C4C4',
  300: '#A5ADCF',
  400: '#5D6588',
  500: '#5D6588',
  550: '#747ca7',
  600: '#34384C',
  700: 'rgba(47, 50, 65, 0.5)',
  800: '#1E1F25',
  900: '#1E1F25',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
  600_5: alpha('#34384C', 0.5),
};

const BLACK = {
  0: '#000',
  100: '#262834',
  200: '#2b2d39',
  300: '#363843',
  400: '#141518',
  300_8: alpha('#363843', 0.8),
  400_6: alpha('#141518', 0.6),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  primaryButton: createHorizontalGradient(PRIMARY.light, PRIMARY.main, PRIMARY.dark),
  secondaryButton: createHorizontalGradient(SECONDARY.dark, SECONDARY.main, SECONDARY.light),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  black: BLACK,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: PRIMARY.lighter,
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  purple: { dark: '#4b32c7', darker: '#4b32c7', lighter: '#d5ccff' },
  positive: { main: '#23F4F9' },
  negative: { main: '#FA2256' },
  warningButton: '#F27F16',
};

const palette = {
  light: {
    ...COMMON,
    common: { black: '#fff', white: '#000' },
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: {
      paper: 'rgba(210, 210, 211, 0.25)',
      modal: '#fff',
      default: '#fff',
      neutral: GREY[200],
      topBackMenu: COMMON.white,
      topMenu: 'rgba(210, 210, 211, 0.25)',
    },
    action: { active: GREY[600], ...COMMON.action },
    grey: {
      ...COMMON.grey,
      0: '#1E1F25',
      100: '#34384C',
      200: '#34384C',
      300: '#5D6588',
      400: '#A5ADCF',
      500: '#1E1F25',
      600: '#E5E6ED',
      700: '#E5E6ED',
      800: '#FFFFFF',
      900: '#F3F3F4',
      600_5: alpha('#E5E6ED', 0.5),
    },
    black: {
      ...COMMON.black,
      0: '#FFF',
      100: '#E5E6ED', //TODO
      200: '#f06292', //TODO
      400_6: alpha('#141518', 0.6), //TODO
    },
    info: {
      ...COMMON.info,
      darkest: '#5D6588', //TODO
    },
    warning: {
      ...COMMON.warning,
      main: '#d9a407', //TODO
    },
  },
  dark: {
    ...COMMON,
    common: { black: '#000', white: '#fff' },
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: {
      paper: GREY[800],
      modal: GREY[800],
      default: '#141518',
      neutral: GREY[500_16],
      topBackMenu: COMMON.black,
      topMenu: COMMON.black,
    },
    action: { active: GREY[300], ...COMMON.action },
    grey: {
      ...COMMON.grey,
      // 0: '#FFFFFF',
      // 300: '#A5ADCF',
      // 400: '#5D6588',
      // 600: '#34384C',
      // 800: '#1E1F25',
    },
    black: {
      ...COMMON.black,
      0: '#000',
      100: '#262834',
      200: '#2b2d39',
    },
    info: {
      ...COMMON.info,
      darkest: '#252D55',
    },
  },
};

export default palette;
