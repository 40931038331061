import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

import Image from '../../../components/Image';
import ModalContainer from '../../../components/ModalContainer';
import successIcon from '../../../assets/success_icon.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import Iconify from '../../../components/Iconify';
import { navigationUrl } from '../../../navigationUrl';

export default function PerfectMoneyStatusModal({ open, onClose, result, orderUrl }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fiatS = result?.fiatType ===  'IRR' ? '' : result?.fiatType ===  'USD' ? 'USD' : ' €'
  return (
    <ModalContainer
      open={open}
      onClose={() => {
        onClose();
        result?.onReset();
      }}
      width={380}
    >
      <Box
        sx={{
          width: 91,
          height: 91,
          backgroundColor: 'grey.600',
          backdropFilter: 'blur(7px)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mx: 'auto',
        }}
      >
        <Image src={successIcon} sx={{ m: 'auto', width: 40 }} />
      </Box>

      <Typography variant='body3' color={'#30E0A1'} sx={{ mt: 3, mb: 3.8, textAlign: 'center' }} component={'p'}>
        {t(' The transaction request registered successfully\n' +
            'Check your history to see the transaction status')}
      </Typography>


      {result?.perfectMoneyAmount && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Voucher Amount')}
          </Typography>



          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {result.perfectMoneyAmount }
            </Typography>
            <CopyToClipboard text={result.perfectMoneyAmount} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>

        </Stack>
      )}


      {result?.amount && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Voucher Amount')}
          </Typography>

          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {`${result.amount} ${fiatS}`}
            </Typography>
            <CopyToClipboard text={result.amount} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )}


      {result?.voucherNum && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Voucher Code')}
          </Typography>
          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {result.voucherNum}
            </Typography>
            <CopyToClipboard text={result.voucherNum} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )}


      {result?.voucherCode && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Voucher Serial Number')}
          </Typography>
          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {result.voucherCode}
            </Typography>
            <CopyToClipboard text={result.voucherCode} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )}

      {result?.voucherActivation && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Voucher Activation Code')}
          </Typography>
          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {result.voucherActivation}
            </Typography>
            <CopyToClipboard text={result.voucherActivation} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )}


      {result?.fiatAmount && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Amount')}
          </Typography>
          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {result.fiatAmount  + fiatS}
            </Typography>
            <CopyToClipboard text={result.fiatAmount} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )}


      {/* {result?.activationCode && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
          <Typography variant='body3' color='grey.300'>
            {t('Activation code')}
          </Typography>
          <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1}>
            <Typography variant='body3' color='grey.100'>
              {result.activationCode}
            </Typography>
            <CopyToClipboard text={result.activationCode} onCopy={() => enqueueSnackbar(t('Copied!'))}>
              <Tooltip title={t('Copy')}>
                <IconButton sx={{ ml: 4.5 }}>
                  <Iconify icon={'ep:copy-document'} sx={{ width: 22, height: 22, color: 'grey.400' }} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )} */}

      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
        <Typography variant='body3' color='grey.300'>
          {t('Date & Time')}
        </Typography>

        <Typography variant='body3' color='grey.100'>
          {moment().format('YYYY/MM/DD - HH:mm')}
        </Typography>
      </Stack>

      <Stack direction={'row'} alignItems='center' justifyContent={'center'} sx={{ mt: 5 }} spacing={2}>
        <Button
          variant='contained'
          color='primary'
          sx={{ width: '55%', typography: 'body3' }}
          size='large'
          onClick={() => navigate(navigationUrl.panel)}
        >
          {t('Dashboard')}
        </Button>

        <Button
          variant='outlined'
          color='inherit'
          sx={{ width: '45%', typography: 'body3' }}
          size='large'
          onClick={() => navigate(orderUrl)}
        >
          {t('Order')}
        </Button>
      </Stack>
    </ModalContainer>
  );
}
