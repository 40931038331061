import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from './Image';
import logo from '../assets/logo.png';
import logo_dark from '../assets/logo_dark.png';

import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function CustomLoading({ imageSize = 64, innerBoxSize = 100, outerBoxSize = 120 }) {

  const theme = useTheme()
  return (
    <>
      <m.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Image src={theme?.palette?.mode === 'dark' ? logo :logo_dark} sx={{ width: 'auto', height : imageSize  }} />
      </m.div>

      <Box
        component={m.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: innerBoxSize,
          height: innerBoxSize,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(theme.palette.positive.main, 0.24)}`,
        }}
      />

      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: outerBoxSize,
          height: outerBoxSize,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(theme.palette.positive.main, 0.24)}`,
        }}
      />
    </>
  );
}
