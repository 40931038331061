import React from 'react';
import { Image, Row, Col, Button as AntButton } from 'antd';
import classes from './forgotPassCheckEmail.module.scss';
import logo from '../../../../assets/l_logo.png';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { navigationUrl } from '../../../../navigationUrl';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';
import {Button, useTheme} from '@mui/material';
import logo_dark from "../../../../assets/l_logo_dark.png";

const ForgotPassCheckEmail = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();

  const colPropsForm = {
    xs: 20,
    sm: 14,
    md: 8,
    lg: 6,
    xl: 6,
  };

  const colPropsImage = {
    xs: 0,
    sm: 0,
    md: 9,
    lg: 9,
    xl: 9,
  };

  // const onTest = () => {
  //   window.location.replace(location?.state?.pageUrl);
  //   // window.location.replace("http://localhost:3000/forgotpass/newpass?otp=123456");
  // };
  const theme = useTheme();

  return (
    // <Row className={classes.container} justify="space-between" align="middle">
    <div className={classes.container}>
      <Row justify='space-around' style={{ marginTop: '50px' }}>
        <Col {...colPropsForm}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image src={theme?.palette?.mode === 'dark' ? logo :logo_dark} sx={{ width: 'auto', height : 24  }} />

          </div>
        </Col>
        <Col {...colPropsImage}></Col>
      </Row>

      <Row justify='center'>
        <Col {...colPropsForm}>
          <div className={classes.formTitleDiv}>
            <span className={classes.formTitleBig}>{t('Check your email')}</span>
            <span className={classes.formTitleSmall}>{t('We send password reset link to')}</span>
            <span className={classes.formTitleSmall}>{location?.state?.emailAddress}</span>
          </div>

          <Button fullWidth size='large' variant='contained' sx={{ typography: 'body3' }}>
            {t('Open email app')}
          </Button>

          <div className={classes.btnBackDiv}>
            <HiOutlineArrowNarrowLeft className={classes.icon} />
            <AntButton
              type='link'
              shape='round'
              className={classes.btnBack}
              onClick={() => navigate(navigationUrl.login)}
            >
              {t('Back to login')}
            </AntButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassCheckEmail;
