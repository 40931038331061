import {alpha, Box, Card, Tab, Tabs, useTheme} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SET_PAGE_STATE } from '../../../store/actionTypes';

import PerfectMoneyForm from './perfectMoneyForm';
import PerfectMoneyStatusModal from './perfectMoneyStatusModal';
import { navigationUrl } from '../../../navigationUrl';

export default function PerfectMoney() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const [type, setType] = useState('NORMAL');
  const theme = useTheme();

  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'E-Money' },
    });
  }, []);

  const [openStatusModal, setOpenStatusModal] = useState(null);

  return (
    <>
      <Box sx={{ mt: 3, mx: { xs: 2, lg: 0 } }}>
        <Box>
          <Tabs
            centered
            value={tab}
            onChange={handleTabChange}
            aria-label='basic tabs example'
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main, // Set the indicator color to primary
              },
            }}
          >
            <Tab
                label={t('Buy')}
                sx={{
                  width: 200,
                  borderBottom: `2px solid ${theme.palette.grey[400]}`,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main, // Set the selected tab color to primary
                    backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
                  },
                }}
            />
            <Tab
                label={t('Sell')}
                sx={{
                  width: 200,
                  borderBottom: `2px solid ${theme.palette.grey[400]}`,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main, // Set the selected tab color to primary
                    backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
                  },
                }}
            />
          </Tabs>
        </Box>

        <TabPanel value={tab} index={0}>
          <PerfectMoneyForm side='BUY' onOpenStatusModal={setOpenStatusModal} keepType={type} setKeepType={setType} />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <PerfectMoneyForm side='SELL' onOpenStatusModal={setOpenStatusModal} keepType={type} setKeepType={setType} />
        </TabPanel>
      </Box>

      <PerfectMoneyStatusModal
        open={!!openStatusModal}
        onClose={() => setOpenStatusModal(null)}
        result={openStatusModal}
        orderUrl={type === 'PAYSAFE' ? navigationUrl.paysafe_history : navigationUrl.perfectMoney_history}
      />
    </>
  );
}

/////////////////////////////////////////////////////////////////
function TabPanel(props) {
  const { children, value, index } = props;

  if (value === index) {
    return (
      <Card sx={{ width: '100%', borderRadius: '12px', p: 0 }}>
        <Box sx={{ width: { sx: '100%', md: 600 }, m: 'auto', mb: 5, py: 5, px: { xs: 2, md: 0 } }}>{children}</Box>
      </Card>
    );
  }
}
