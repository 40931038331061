import TicketCounter from './components/TicketCounter';
import WaitingCounter, {
  BankCardWaitingCounter,
  WithdrawWaitingCounter,
  DepositWaitingCounter,
  UserWaitingCounter,
} from './components/WaitingCounter';
import { navigationUrl } from './navigationUrl';

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}
const menuImages = importAll(require.context('./assets/sideMenu', false, /\.(png|jpe?g|svg)$/));

export const userNavConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        path: navigationUrl.panel,
        icon: menuImages['dashboard.svg'],
      },
      {
        title: 'Wallet',
        path: navigationUrl.wallet,
        icon: menuImages['wallet.svg'],
        children: [
          { title: 'Funding Wallet', path: navigationUrl.wallet },
          { title: 'Futures Wallet', path: navigationUrl.futures_wallet },
        ],
      },
      {
        title: 'Open trade',
        path: navigationUrl.openTrade,
        icon: menuImages['openTrade.svg'],
      },
    ],
  },
  {
    subheader: 'Market',
    items: [
      {
        title: 'Trade',
        path: navigationUrl.trade,
        icon: menuImages['trade.svg'],
      },
      {
        title: 'P2P',
        path: navigationUrl.p2p_list,
        icon: menuImages['p2p.svg'],
      },
      {
        title: 'E-Money',
        path: navigationUrl.perfectMoney,
        icon: menuImages['e-money.svg'],
      },
      {
        title: 'Convert',
        path: navigationUrl.convert,
        icon: menuImages['convert.svg'],
      },
      {
        title: 'Crypto',
        icon: menuImages['buyCrypto.svg'],
        children: [
          { title: 'Deposit Crypto', path: navigationUrl.deposit_crypto_request },
          { title: 'Withdraw Crypto', path: navigationUrl.withdraw_crypto_request },
        ],
      },
      { title: 'OTC', icon: menuImages['buyCrypto.svg'], path: navigationUrl.otc },
      {
        title: 'Futures',
        path: navigationUrl.futures,
        icon: menuImages['futures.svg'],
      },
      {
        title: 'Market',
        path: navigationUrl.market,
        icon: menuImages['market.svg'],
      },
    ],
  },
  {
    subheader: 'Possibilities',
    items: [
      {
        title: 'Referrals & Income',
        path: navigationUrl.referrals_income,
        icon: menuImages['referrals&Income.svg'],
      },
      {
        title: 'Ticket',
        path: navigationUrl.ticket_list,
        icon: menuImages['ticket.svg'],
      },
      {
        title: 'History',
        icon: menuImages['history.svg'],
        children: [
          { title: 'Trades History', path: navigationUrl.history_trade },
          { title: 'Orders History', path: navigationUrl.order_history },
          { title: 'P2P History', path: navigationUrl.p2p_history },
          { title: 'Paysafe History', path: navigationUrl.paysafe_history },
          { title: 'Perfect Money History', path: navigationUrl.perfectMoney_history },
          { title: 'Convert History', path: navigationUrl.convert_history },
          { title: 'Internal Transactions', path: navigationUrl.internal_transaction },
          { title: 'Futures History', path: navigationUrl.futures_history },
          { title: 'Crypto Transactions', path: navigationUrl.crypto_transaction },
          { title: 'Fiat Transactions', path: navigationUrl.fiat_transaction },
        ],
      },
    ],
  },
  {
    subheader: 'Possibilities',
    items: [
      {
        title: 'Authentication',
        path: navigationUrl.auth,
        icon: menuImages['authentication.svg'],
      },
      {
        title: 'Bank Card',
        path: navigationUrl.bankcard,
        icon: menuImages['bankCard.svg'],
      },
      {
        title: 'Account settings',
        path: navigationUrl.accountSetting,
        icon: menuImages['accountSettings.svg'],
      },
      {
        title: 'Academy',
        path: navigationUrl.learn_academy,
        icon: menuImages['academy.svg'],
      },
    ],
  },
];

export const adminNavConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        path: navigationUrl.admin,
        icon: menuImages['dashboard.svg'],
      },
      {
        title: 'Tickets',
        path: navigationUrl.admin_ticket_list,
        icon: menuImages['ticketAdmin.svg'],
        info: <TicketCounter />,
      },
      // {
      //   title: 'Order',
      //   path: navigationUrl.admin_order,
      //   icon: menuImages['order.svg'],
      // },
      {
        title: 'History',
        icon: menuImages['historyAdmin.svg'],
        children: [
          { title: 'Trades History', path: navigationUrl.admin_history_trade },
          { title: 'Open Trades', path: navigationUrl.admin_open_trade },
          { title: 'Orders History', path: navigationUrl.admin_order_history },
          { title: 'P2P History', path: navigationUrl.admin_p2p_history },
          { title: 'Paysafe History', path: navigationUrl.admin_paysafe_history },
          { title: 'Perfect Money History', path: navigationUrl.admin_perfectMoney_history },
          { title: 'Convert History', path: navigationUrl.admin_convert_history },
          { title: 'Internal Transactions', path: navigationUrl.admin_internal_transaction },
          { title: 'Futures History', path: navigationUrl.admin_futures_history },
          // { title: 'Crypto Transactions', path: navigationUrl.admin_crypto_transaction },
          // { title: 'Fiat Transactions', path: navigationUrl.admin_fiat_transaction },
        ],
      },
      {
        title: 'Waiting Actions',
        icon: menuImages['notification.svg'],
        info: <WaitingCounter />,
        children: [
          { title: 'Users waiting for Verify', path: navigationUrl.admin_user_waiting, info: <UserWaitingCounter /> },
          {
            title: 'List Of All Bank Cards',
            path: navigationUrl.admin_bank_card,
            icon: menuImages['listOfAllBankCards.svg'],
            info: <BankCardWaitingCounter />,
          },
          {
            title: 'Withdraw Fiat Waiting',
            path: navigationUrl.admin_fiat_withdraw_waiting,
            info: <WithdrawWaitingCounter />,
          },
          {
            title: 'Deposit Fiat Waiting',
            path: navigationUrl.admin_fiat_deposit_waiting,
            info: <DepositWaitingCounter />,
          },
        ],
      },
      // {
      //   title: 'Admin Utilities',
      //   icon: menuImages['profileTick.svg'],
      //   children: [
      //     { title: 'Account Settings', path: navigationUrl.admin_account_setting },
      //     { title: 'Profile', path: navigationUrl.admin_edit(0) },
      //     { title: 'Security verification', path: navigationUrl.admin_setting_verification },
      //   ],
      //   // children: [
      //   //   { title: 'Security verification', path: navigationUrl.admin_setting_verification },
      //   //   // { title: 'Tether Setting', path: navigationUrl.admin_setting_tether },
      //   //   {
      //   //     title: 'Inventory Settings',
      //   //     path: navigationUrl.admin_setting_inventory,
      //   //   },
      //   //   // {
      //   //   //   title: 'Daily Rial withdrawal ceiling',
      //   //   //   path: navigationUrl.admin_setting_withdrawal_ceiling,
      //   //   // },
      //   //   // {
      //   //   //   title: 'Buy Digital Currency',
      //   //   //   path: navigationUrl.admin_setting_digital_currency,
      //   //   // },
      //   // ],
      // },
      {
        title: 'API settings',
        icon: menuImages['accountSettingsAdmin.svg'],
        children: [
          { title: 'Gateway Setting', path: navigationUrl.admin_setting_dedicatedGateway },
          // {
          //   title: 'Voucher Settings',
          //   path: navigationUrl.admin_setting_voucher,
          // },
          {
            title: 'Pay Safe Settings',
            path: navigationUrl.admin_setting_paySafe,
          },
          {
            title: 'Perfect Money Settings',
            path: navigationUrl.admin_setting_perfectMoney,
          },
          { title: 'Cryptocurrency Setting', path: navigationUrl.admin_setting_cryptoCurrency },
          {
            title: 'Inventory Settings',
            path: navigationUrl.admin_setting_inventory,
          },
        ],
      },
      {
        title: 'Token Listing',
        icon: menuImages['tokenListing.svg'],
        children: [
          {
            title: 'Tokens',
            path: navigationUrl.admin_token_list,
          },
          {
            title: 'Add token',
            path: navigationUrl.admin_token_add,
          },
          // {
          //   title: 'Main wallet setting',
          //   path: navigationUrl.admin_token_main_wallet_setting,
          // },
          // {
          //   title: 'Network setting',
          //   path: navigationUrl.admin_token_network_setting,
          // },
        ],
      },
      {
        title: 'Wallet Settings',
        icon: menuImages['network-settings-solid.svg'],
        children: [
          {
            title: 'GasPump wallet setting',
            path: navigationUrl.admin_token_gas_pump_wallet_setting,
          },
          {
            title: 'Main Wallet Settings',
            path: navigationUrl.admin_token_main_wallet_setting,
          },
          {
            title: 'Dedicated Wallet Settings',
            path: navigationUrl.admin_dedicated_wallet_settings,
          },
          // {
          //   title: 'Network setting',
          //   path: navigationUrl.admin_token_network_setting,
          // },
        ],
      },
      // {
      //   title: 'Dedicated Wallet Settings',
      //   path: navigationUrl.admin_dedicated_wallet_settings,
      //   icon: menuImages['network-settings-solid.svg'],
      // },
      {
        title: 'Settings Network',
        path: navigationUrl.admin_setting_network,
        icon: menuImages['network-settings.svg'],
      },
      {
        title: 'Settings',
        icon: menuImages['admin-setting.svg'],
        children: [
          {
            title: 'General settings',
            path: navigationUrl.admin_setting_general,
          },
          {
            title: 'IP Block',
            path: navigationUrl.admin_setting_ipBlock,
          },
          {
            title: 'User level settings',
            path: navigationUrl.admin_user_level_setting,
          },
          {
            title: 'Futures settings',
            path: navigationUrl.futures_setting,
          },
          {
            title: 'Deposit With Receipt',
            path: navigationUrl.admin_setting_deposit,
          },
          {
            title: 'Popup Settings',
            path: navigationUrl.admin_setting_popup,
          },
          {
            title: 'Commission settings',
            path: navigationUrl.admin_setting_commission,
          },
          {
            title: 'Related To Reference Account',
            path: navigationUrl.admin_related_reference_account,
          },
          {
            title: 'Automatic Deposit settings',
            path: navigationUrl.admin_setting_auto_deposit,
          },
        ],
      },

      {
        title: 'Users',
        icon: menuImages['twoUser.svg'],
        children: [
          {
            title: 'All users',
            path: navigationUrl.admin_user,
          },
          {
            title: 'Add user',
            path: navigationUrl.admin_add_user,
          },
        ],
      },
      {
        title: 'Admins',
        icon: menuImages['profileTick.svg'],
        children: [
          {
            title: 'Admins',
            path: navigationUrl.admin_list,
          },
          {
            title: 'Add Admin',
            path: navigationUrl.admin_new,
          },
        ],
      },
      {
        title: 'Referral marketing',
        path: navigationUrl.admin_referral_marketing,
        icon: menuImages['referralMarketing.svg'],
      },
      {
        title: 'Crypto Wallet Transactions',
        path: navigationUrl.admin_wallet_crypto_transaction,
        icon: menuImages['cryptoWalletTransactions.svg'],
      },
      {
        title: 'Fiat Wallet Transaction',
        path: navigationUrl.admin_wallet_transaction,
        icon: menuImages['walletTransactions.svg'],
      },
      // {
      //   title: 'Automatic Transactions',
      //   path: navigationUrl.admin_automatic_transaction,
      //   icon: menuImages['automaticTransactions.svg'],
      // },
      {
        title: 'Automatic Deposit History',
        path: navigationUrl.admin_automatic_deposit_history,
        icon: menuImages['automaticTransactions.svg'],
      },
      // {
      //   id: "user's",
      //   title: "User's",
      //   path: navigationUrl.landing,
      //   icon: menuImages["user's.svg"],
      // },
      {
        title: 'Notification',
        path: navigationUrl.admin_setting_notification,
        icon: menuImages['notification.svg'],
      },
      {
        title: 'Reporting',
        path: navigationUrl.admin_reporting,
        icon: menuImages['reporting.svg'],
      },
    ],
  },
];

export const landingNavConfig = [
  {
    icon: '/assets/landing/top-menu/dashboard.svg',
    path: navigationUrl.panel,
    children: [
      {
        items: [
          { title: 'Exchange', path: navigationUrl.trade, icon: '/assets/landing/top-menu/trade.svg' },
          { title: 'Crypto convert', path: navigationUrl.convert, icon: '/assets/landing/top-menu/convert.svg' },
          { title: 'Buy Crypto', path: navigationUrl.trade, icon: '/assets/landing/top-menu/buyCrypto.svg' },
          {
            title: 'Futures Classic',
            path: navigationUrl.futures,
            icon: '/assets/landing/top-menu/futuresClassic.svg',
          },
        ],
      },
      {
        items: [
          { title: 'Wallet', path: navigationUrl.wallet, icon: '/assets/landing/top-menu/wallet.svg' },
          { title: 'Market', path: navigationUrl.market, icon: '/assets/landing/top-menu/market.svg' },
          { title: 'Referral', path: navigationUrl.referrals_income, icon: '/assets/landing/top-menu/userOctagon.svg' },
          { title: 'Academy', path: navigationUrl.learn_academy, icon: '/assets/landing/top-menu/academy.svg' },
        ],
      },
      {
        items: [
          { title: 'Token Listing', path: navigationUrl.tokens, icon: '/assets/landing/top-menu/tokenListing.svg' },
          { title: 'APIs', path: navigationUrl.panel, icon: '/assets/landing/top-menu/api.svg' },
          { title: 'iOS App', path: navigationUrl.learn_iosApp, icon: '/assets/landing/top-menu/iosMobile.svg' },
          {
            title: 'Android App',
            path: navigationUrl.learn_androidApp,
            icon: '/assets/landing/top-menu/androidMobile.svg',
          },
        ],
      },
    ],
  },
  {
    title: 'Buy Crypto',
    children: [
      {
        items: [
          { title: 'Bank deposit', path: navigationUrl.deposit_crypto_list, icon: '/assets/landing/top-menu/bank.svg' },
          { title: 'Credit/Debit card', path: navigationUrl.otc, icon: '/assets/landing/top-menu/card.svg' },
          { title: 'P2P trading', path: navigationUrl.p2p_list, icon: '/assets/landing/top-menu/p2p.svg' },
        ],
      },
    ],
  },
  {
    title: 'Markets',
    path: navigationUrl.market,
  },
  {
    title: 'Trade',
    children: [
      {
        items: [
          { title: 'Crytizi Convert', path: navigationUrl.convert, icon: '/assets/landing/top-menu/convert.svg' },
          { title: 'Spot', path: navigationUrl.futures, icon: '/assets/landing/top-menu/trade.svg' },
          { title: 'Margin', path: navigationUrl.futures, icon: '/assets/landing/top-menu/margin.svg' },
          { title: 'P2P', path: navigationUrl.p2p_list, icon: '/assets/landing/top-menu/p2p.svg' },
          {
            title: 'Futures',
            path: navigationUrl.futures,
            icon: '/assets/landing/top-menu/strategyTrading.svg',
          },
        ],
      },
    ],
  },
  {
    title: 'Academy',
    path: navigationUrl.learn_academy,
  },
  {
    title: 'Wallet',
    path: navigationUrl.wallet,
  },
];

export const footerNavConfig = [
  {
    subheader: 'About Us',
    children: [
      { title: 'FAQ', path: navigationUrl.faq },
      { title: 'About Us', path: navigationUrl.about },
      { title: 'Contact Us', path: navigationUrl.contact },
      { title: 'Terms Of Use', path: navigationUrl.terms },
      { title: 'Privacy policy', path: navigationUrl.privacy },
    ],
  },
  {
    subheader: 'Trade',
    children: [
      { title: 'P2P', path: navigationUrl.p2p_request },
      { title: 'Spot', path: navigationUrl.spot },
      { title: 'Margin', path: navigationUrl.margin },
      { title: 'E-money', path: navigationUrl.perfectMoney },
      { title: 'AIO Convert', path: navigationUrl.convert },
    ],
  },
  {
    subheader: 'Service',
    children: [
      { title: 'Token listing', path: navigationUrl.tokens },
      { title: 'Bank deposit', path: navigationUrl.panel },
      { title: 'Buy/Sell Crypto', path: navigationUrl.otc },
      { title: 'Coin-M Futures', path: navigationUrl.panel },
      { title: 'USDⓈ-M Futures', path: navigationUrl.panel },
    ],
  },
  {
    subheader: 'Learn',
    children: [
      { title: 'Verify', path: navigationUrl.learn_earn },
      { title: 'iOS App', path: navigationUrl.learn_iosApp },
      { title: 'Android App', path: navigationUrl.learn_androidApp },
      { title: 'Announcement', path: navigationUrl.learn_earn },
      { title: 'Cryptizi Academy', path: navigationUrl.learn_academy },
    ],
  },
  {
    subheader: 'More',
    children: [
      { title: 'API', path: navigationUrl.panel },
      { title: 'Fees', path: navigationUrl.panel },
      { title: 'Market', path: navigationUrl.market },
      { title: 'OTC Terms', path: navigationUrl.otcTerms },
      { title: 'Convert Terms', path: navigationUrl.convertTerms },
    ],
  },
  {
    subheader: 'Community',
    grid: { xs: 4 },
    children: [
      {
        icon: '/assets/landing/footer-menu/instagram.svg',
        path: 'https://instagram.com/cryptizi.official?igshid=MzRlODBiNWFlZA==',
      },
      { icon: '/assets/landing/footer-menu/facebook.svg', path: 'https://www.facebook.com/cryptizi/' },
      { icon: '/assets/landing/footer-menu/telegram.svg', path: 'https://t.co/wz6hL72RAW' },

      {
        icon: '/assets/landing/footer-menu/whatsapp.svg',
        path: 'https://www.youtube.com/channel/UCzr_VL1L6Fk3q1xV8TNm0Ow',
      },
      { icon: '/assets/landing/footer-menu/youtube.svg', path: '#' },
      { icon: '/assets/landing/footer-menu/twitter.svg', path: '#' },

      { icon: '/assets/landing/footer-menu/medium.svg', path: 'https://medium.com/@criptizi' },
      { icon: '/assets/landing/footer-menu/github.svg', path: 'https://github.com/Cryptizi' },
      { icon: '/assets/landing/footer-menu/redit.svg', path: 'https://www.reddit.com/user/cryptizi' },
    ],
  },
];

export const accessUrls = {
  [navigationUrl.learn_academy]: 'ACADEMY',

  [navigationUrl.admin_list]: 'ADMINS',
  [navigationUrl.admin_new]: 'ADMINS',

  [navigationUrl.admin_setting_dedicatedGateway]: 'API_SETTING',
  [navigationUrl.admin_setting_paySafe]: 'API_SETTING',
  [navigationUrl.admin_setting_perfectMoney]: 'API_SETTING',
  [navigationUrl.admin_setting_cryptoCurrency]: 'API_SETTING',
  [navigationUrl.admin_setting_inventory]: 'API_SETTING',

  [navigationUrl.admin_fiat_waiting]: 'FIAT_TRANSACTION_WAITING',
  [navigationUrl.admin_bank_card]: 'LIST_OF_ALL_BANK_CARD',
  [navigationUrl.admin_setting_network]: 'NETWORK_SETTING',

  [navigationUrl.admin_setting_general]: 'SETTING',
  [navigationUrl.admin_setting_ipBlock]: 'SETTING',
  [navigationUrl.admin_user_level_setting]: 'SETTING',
  [navigationUrl.admin_setting_deposit]: 'SETTING',
  [navigationUrl.admin_setting_popup]: 'SETTING',
  [navigationUrl.admin_setting_commission]: 'SETTING',
  [navigationUrl.admin_related_reference_account]: 'SETTING',
  [navigationUrl.admin_setting_auto_deposit]: 'SETTING',

  [navigationUrl.admin_setting_notification]: 'NOTIFICATION',
  [navigationUrl.admin_order]: 'ORDERS',
  [navigationUrl.admin_referral_marketing]: 'REFERRALS',
  [navigationUrl.admin_reporting]: 'REPORTING',

  [navigationUrl.admin_ticket_list]: 'TICKETS',
  [navigationUrl.admin_ticket_new]: 'TICKETS',

  [navigationUrl.admin_user]: 'USERS',
  [navigationUrl.admin_add_user]: 'USERS',

  [navigationUrl.admin_user_waiting]: 'USERS_WAITING_FOR_VERIFY',
  [navigationUrl.admin_token_list] : 'TOKEN_LISTING',
  [navigationUrl.fullNodePage] : 'DEDICATED_WALLET',

  // [navigationUrl.admin_token_main_wallet_setting] : 'TOKEN_LISTING',
  [navigationUrl.admin_token_add] : 'TOKEN_LISTING',

  [navigationUrl.admin_token_gas_pump_wallet_setting] : 'GAS_CONFIG',
  [navigationUrl.admin_token_main_wallet_setting] : 'MAIN_WALLET',


  // []: 'WAITING_ACTIONS',
};
