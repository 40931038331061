import {alpha, Box, Button, Card, Divider, Grid, InputAdornment, Tab, Tabs, Typography, useTheme} from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import SecurityVerificationModal from './securityVerificationModal';
import { navigationUrl } from '../../../../navigationUrl';
import WithdrawSuccessModal from './withdrawSuccessModal';
import WithdrawFAQ from './withdrawFAQ';
import Iconify from '../../../../components/Iconify';
import SymbolIcon from '../../../../components/SymbolIcon';
import useWithdrawRequest from './hooks/useWithdrawRequest';
import ActiveCryptoModal from '../../../../components/activeCryptoModal/activeCryptoModal';
import SelectNetworkModal from '../../../../components/SelectNetworkModal';
import WithdrawCryptoInfo from './withdrawCryptoInfo';
import WithdrawCryptoExternal from './withdrawCryptoExternal';
import WithdrawCryptoInternal from './withdrawCryptoInternal';
import { NumberField } from '../../../../components/NumberField';
import useWallet from '../../../../hooks/useWallet';
import useQuery from '../../../../hooks/useQuery';

export default function WithdrawCryptoForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Withdraw Crypto' },
    });
  }, []);

  const [tab, setTab] = useState('external');
  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  const { userWallet } = useWallet();

  const [openNetworkModal, setOpenNetworkModal] = useState(false);
  const [openSymbolModal, setOpenSymbolModal] = useState(false);
  const [openSecurityVerificationModal, setOpenSecurityVerificationModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(null);
  const [defaultNetwork, setDefaultNetwork] = useState(null);

  const {
    networkSelected,
    symbolSelected,
    onSelectNetwork,
    onSelectSymbol,
    amount,
    setAmount,
    onSubmit,
    isSubmitting,
    symbolSelectedWallet,
    resetForm,
    externalValues,
    onChangeExternalValues,
    internalValues,
    onChangeInternalValues,
    recipientType,
    setRecipientType,
    fee
  } = useWithdrawRequest({
    setOpenSecurityVerificationModal,
    isExternal: tab === 'external',
    userWallet,
  });

  const onOpenNetworkModal = () => {
    if (!symbolSelected) {
      return enqueueSnackbar(t('Please select a coin firstly!'), { variant: 'warning' });
    }
    setOpenNetworkModal(true);
  };

  useEffect(() => {
    if (query.get('symbol')) onSelectSymbol(query.get('symbol'));
  }, [query]);

  const onSuccess = () => {
    setOpenSuccessModal({
      symbolSelected: symbolSelected,
      networkSelected: { ...networkSelected },
      address: externalValues?.address,
      amount: amount,
      internalValues,
      fee
    });
    resetForm();
  };
  const theme = useTheme();


  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8}>
          <Card sx={{ px: { xs: 2, md: 3 }, py: { xs: 3, md: 3 }, mx: { xs: 2, md: 0 } }}>
            <Stack spacing={5}>
              <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                <Typography variant='h6' sx={{ fontWeight: 500 }}>
                  {t('Withdraw Crypto')}
                </Typography>

                <Button
                  variant={'outlined'}
                  color={'inherit'}
                  size='medium'
                  onClick={() => navigate(`${navigationUrl.crypto_transaction}?type=withdraw`)}
                  sx={{ borderColor: 'grey.400', py: 1, typography: 'body3' }}
                >
                  {t('Recent Withdrawals')}
                </Button>
              </Stack>

              <Stack spacing={2.5}>
                {/* ************************** select coin ************************** */}
                <Stack alignItems={'flex-start'}>
                  <Typography variant='body3' sx={{ color: 'grey.400' }}>
                    {t('Coin')}
                  </Typography>

                  <Button
                    fullWidth
                    color='inherit'
                    sx={{ borderRadius: 0, maxWidth: 550, justifyContent: 'space-between' }}
                    onClick={() => setOpenSymbolModal(true)}
                    endIcon={
                      <Iconify icon={'eva:arrow-ios-downward-fill'} sx={{ width: 15, height: 15, color: 'grey.300' }} />
                    }
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {symbolSelected ? (
                        <>
                          <SymbolIcon symbol={symbolSelected} width={22} height={22} />
                          <Typography variant='body2' sx={{ color: 'grey.100' }}>
                            {symbolSelected}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant='body2' sx={{ color: 'grey.100' }}>
                          {t('Select Coin')}
                        </Typography>
                      )}
                    </Stack>
                  </Button>

                  <Divider sx={{ width: '100%', maxWidth: 550 }}></Divider>
                </Stack>

                {/* ************************** enter amount ************************** */}
                <NumberField
                  label={'Amount'}
                  variant='standard'
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  thousandSeparator
                  fullWidth
                  placeholder={t('Enter Amount')}
                  sx={{ maxWidth: 550 }}

                  helperText={
                    tab === 'external' ?
                    `${networkSelected?.withdrawFee ?  t('Withdraw Fee')+' : ' : ''} ${networkSelected?.withdrawFee ? networkSelected?.withdrawFee+' | ' : ''}  ${t('Final amount')}: ${
                      parseFloat(amount?.toString()?.replaceAll(',', '')) + parseFloat((networkSelected?.withdrawFee || 0))
                    }` : `${fee?.fee ?  t('Withdraw Fee')+' : ' : ''} ${fee?.fee ? fee?.fee+' | ' : ''}  ${fee?.min ?  t('Min amount')+' : ' : ''} ${fee?.min ? fee?.min+' | ' : ''}  ${t('Final amount')}: ${
                        parseFloat(amount?.toString()?.replaceAll(',', '')) + parseFloat((fee?.fee || 0))} `
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {symbolSelectedWallet && (
                          <Stack direction='row' spacing={1}>
                            <Button
                              size='small'
                              color='warning'
                              sx={{
                                typography: 'body1',
                                fontWeight: 900,
                                p: 0,
                                minWidth: 50,
                                height: 24,
                                borderRadius: 0.6,
                              }}
                              onClick={() => setAmount(symbolSelectedWallet?.available)}
                            >
                              {t('MAX')}
                            </Button>
                            <Divider orientation='vertical' variant='middle' flexItem />
                            <Typography variant='body1_secondary' sx={{ fontWeight: 900 }}>
                              {symbolSelected || ''}
                            </Typography>
                          </Stack>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            {/* ************************** tabs ************************** */}
            <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: 400, mt: 5 }}>
              <Tabs value={tab} onChange={handleTabChange}   sx={{
                '& .Mui-selected': {
                  color: 'primary.main', // Set the selected tab color to primary
                  backgroundColor: alpha(theme.palette.primary.main, 0.2)

                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main', // Set the indicator color to primary
                },
              }}>
                <Tab label={t('Address')} sx={{ width: '50%' ,

                  borderBottom: `2px solid ${theme.palette.grey[400]}`,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main, // Set the selected tab color to primary
                    backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
                  },
                }} value='external' />
                <Tab label={t('AIO user')} sx={{ width: '50%',
                  borderBottom: `2px solid ${theme.palette.grey[400]}`,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main, // Set the selected tab color to primary
                    backgroundColor: alpha(theme.palette.primary.main, 0.2), // Set background with opacity
                  },
                }} value='internal' />
              </Tabs>
            </Box>

            <TabPanel value={tab} index={'external'}>
              <WithdrawCryptoExternal
                coin={symbolSelected}
                onOpenNetworkModal={onOpenNetworkModal}
                networkSelected={networkSelected}
                externalValues={externalValues}
                onChangeExternalValues={onChangeExternalValues}
                userWallet={userWallet}
              />
            </TabPanel>

            <TabPanel value={tab} index={'internal'}>
              <WithdrawCryptoInternal
                internalValues={internalValues}
                onChangeInternalValues={onChangeInternalValues}
                recipientType={recipientType}
                setRecipientType={setRecipientType}
              />
            </TabPanel>

            <WithdrawCryptoInfo
              isExternal={tab === 'external'}
              networkSelected={networkSelected}
              symbolSelected={symbolSelected}
              defaultNetwork={defaultNetwork}
              balance={symbolSelectedWallet?.available}
            />

            <Box>
              <LoadingButton
                variant='contained'
                size='large'
                fullWidth
                loading={isSubmitting}
                onClick={onSubmit}
                sx={{ typography: 'body3_secondary', fontWeight: 500, maxWidth: 450, mt: 8 }}
              >
                {t('withdrawal')}
              </LoadingButton>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <WithdrawFAQ />
        </Grid>
      </Grid>

      <SecurityVerificationModal
        open={openSecurityVerificationModal}
        onClose={() => setOpenSecurityVerificationModal(false)}
        onSuccess={onSuccess}
        symbolSelected={symbolSelected}
        networkSelected={networkSelected}
        address={externalValues.address}
        amount={amount}
        fee={fee}
      />

      <WithdrawSuccessModal
        open={!!openSuccessModal}
        withdrawFee={openSuccessModal?.networkSelected?.withdrawFee}
        finalAmount={openSuccessModal?.fee?.fee  ?   parseFloat(openSuccessModal?.amount?.toString()?.replaceAll(',', '')) - (openSuccessModal?.fee?.fee || 0) : parseFloat(openSuccessModal?.amount?.toString()?.replaceAll(',', '')) - (openSuccessModal?.networkSelected?.withdrawFee || 0)}
        internalValues={openSuccessModal?.internalValues}
        onClose={() => setOpenSuccessModal(null)}
        symbolSelected={openSuccessModal?.symbolSelected}
        networkSelected={openSuccessModal?.networkSelected}
        address={openSuccessModal?.externalValues?.address}
        amount={openSuccessModal?.amount}
        fee={openSuccessModal?.fee}
      />

      <ActiveCryptoModal open={openSymbolModal} onClose={() => setOpenSymbolModal(false)} onSelect={onSelectSymbol} />

      <SelectNetworkModal
        open={openNetworkModal}
        onClose={() => setOpenNetworkModal(false)}
        symbol={symbolSelected}
        onSelect={onSelectNetwork}
        setDefault={setDefaultNetwork}
        isWithdraw
      />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return <Box sx={{ mt: 2.5, display: value === index ? 'block' : 'none' }}>{children}</Box>;
}
