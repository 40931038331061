import { alpha, Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { PageTitle } from '../../../components/PageTitle';
import Api from '../../../http/serviceApi';
import { useSnackbar } from 'notistack';

const percentageButtons = [0, 5, 10, 15, 20, 25, 30];

export default function ReferralsShare({ share }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // -------------------------
  const [percentageSelected, setPercentageSelected] = useState(share);

  // -------------------------
  const onShare = async (percent) => {
    setPercentageSelected(percent);

    try {
      const response = await Api.setReferralInfo(percent);

      if (response?.status === 'SUCCESS') {
        enqueueSnackbar('Share percentage changed successfully.', { variant: 'success' });
      }
    } catch (e) {}
  };

  // -------------------------
  return (
    <Box sx={{ maxWidth: 700 }}>
      <PageTitle
        title='Set percentage for submissions'
        subTitle='30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.'
      />

      <Stack direction={'row'} spacing={1} sx={{ mt: 5, mb: 5.3 }}>
        {percentageButtons.map((percentage, index) => (
          <Button
            key={index}
            variant='contained'
            color='success'
            onClick={() => onShare(percentage)}
            sx={{
              background: percentageSelected === percentage ? 'primary.main' : '#ccc',
              border: '1px solid grey.600',
              minWidth: { xs: 38, md: 65 },
              px: 0,
              color: percentageSelected === percentage ? 'common.black' : '#3d3d3d',
              typography: 'body3',
              borderRadius: 0,
            }}
          >
            {percentage}%
          </Button>
        ))}
      </Stack>

      <Stack spacing={2}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            width: { xs: '100%', md: 510 },
            height: { xs: 40, md: 98 },
            backgroundColor: alpha('#F27F16', 0.2),
            borderRadius: 1.5,
            px: 5,
            py: 4.6,
          }}
        >
          <Typography variant='body2'>{t('Your Share')}</Typography>
          <Typography variant='h6' fontWeight={400}>
            {30 - percentageSelected} %
          </Typography>
        </Stack>

        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            width: { xs: '100%', md: 510 },
            height: { xs: 40, md: 98 },
            backgroundColor: alpha('#30E0A1', 0.2),
            borderRadius: 1.5,
            px: 5,
            py: 4.6,
          }}
        >
          <Typography variant='body2'>{t('Share friends')}</Typography>
          <Typography variant='h6' fontWeight={400}>
            {percentageSelected} %
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
