import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Image from './Image';

import visa from '../assets/logo/visa.svg';
import wallet from '../assets/logo/wallet.svg';
import walletLight from '../assets/logo/wallet_light.svg';
import fast from '../assets/logo/fast.svg';
import shetab from '../assets/logo/shetab.svg';
import { useSelector } from 'react-redux';

export default function PaymentMethod({ onSelectGateway, paymentMethod, disableItems = [] ,emoney = false}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  const gateways = [
    {
      type: 'DEBIT',
      title: 'Visa/Mastercards',
      image: visa,
    },
    ...(isIranianPaymentActive && emoney === false
      ? [
          {
            type: 'SHETAB',
            title: 'Shetab Card',
            image: shetab,
          },
        ]
      : []),
    {
      type: 'FAST_PAY',
      title: 'Fast Pay',
      image: fast,
    },
    {
      type: 'WALLET',
      title: 'Wallet',
      image: theme.palette.mode === 'light' ? walletLight : wallet,
    },
  ];

  console.log('a' , paymentMethod)
  return (
    <>
      <Box>
        <Typography variant='body3' color='grey.400' component='p' sx={{ fontWeight: 400, mb: 1 }}>
          {t('Payment method')}
        </Typography>

        <Stack spacing={{ xs: 0, md: 1 }} direction='row' flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
          {gateways.map((gateway) => (
            <Button
              variant='outlined'
              color='inherit'
              disabled={disableItems.indexOf(gateway.type) !== -1}
              key={gateway.type}
              onClick={() => onSelectGateway(gateway.type)}
              sx={{
                width: { xs: '50%', md: '100%' },
                height: 87,
                borderRadius: 1,
                borderColor: paymentMethod === gateway.type ? 'grey.0' : 'grey.400',
                opacity: disableItems.indexOf(gateway.type) !== -1 ? 0.7 : 1,
              }}
            >
              <Stack spacing={1}>
                <Image src={gateway.image} sx={{ height: 32, '& img': { objectFit: 'contain' } }} />
                <Typography variant='body3' color='grey.300'>
                  {t(gateway.title)}
                </Typography>
              </Stack>
            </Button>
          ))}
        </Stack>
      </Box>

      <Typography variant='body3' color='grey.300' component='p' sx={{ mt: 2 }}>
        {t('By charging the wallet, make your payment without going to the bank portal')}
      </Typography>
    </>
  );
}
